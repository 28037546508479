<template>
  <div>
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Contacto</span>

        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Empresa</span>


        <!-- 
        <v-icon class="fz-34 text-white"> </v-icon> -->

        <img src="../../_core/images/iconos.png" clasS="icono-family2" alt="">
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">Portafolio</span>

        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <div class="bg-page mt-5">
      <img src="../../_core/images/bg-profile.jpg" alt="">

      <section class="px-3 pt-3 pb-4 pb-md-5 section-public section-public-profile">
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-9 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <img max-width="200px" width="100%" class="d-block mx-auto" src="../../_core/images/logo_inicio.png" />
          </div>

          <div class="mt-2 px-3" v-if="template">
            <div class="template w-100 overflow-hidden" v-html="template"></div>
          </div>
        </div>

        <div class="row justify-content-center pt-3 pt-md-4" v-if="photo && !template">
          <div class="col-12 col-md-8 col-lg-6 col-xl-4">
            <div class="photo-profile">
              <img :src="photo" class="d-block mx-auto rounded-circle overflow-hidden" width="150" height="150" alt="">
              <!-- <img :src="photo" class="d-block mx-auto rounded-circle overflow-hidden" width="150" height="150" alt=""> -->
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center pt-4">
          <div class="col-10 col-md-8" v-if="!template">
            <h1 class="my-0 text-center text-white fw-600 fz-20" v-if="names || surnames">{{names + ' ' +surnames}}</h1>

            <h3 class="mt-2 mb-0 text-center text-dark-warning fw-600 fz-20" v-if="position">{{position}}</h3>
            
            <ul class="mt-3 mx-0 p-0 list-unstyled text-center text-white fz-16 list-recorted">
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="phone_number">
                <v-icon class="mr-2 text-white">mdi-phone-outline</v-icon>
                {{phone_number}}
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="address">
                <v-icon class="mr-2 text-white">mdi-map-marker</v-icon>
                {{address}}
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-nowrap" v-if="email">
                <v-icon class="mr-2 text-white">mdi-email</v-icon>
                <a :href="'mailto:'+email" target="_blank">{{email}}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="website">
                <v-icon class="mr-2 text-white">mdi-web</v-icon>
                <a :href="website" target="_blank">{{website}}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="facebook">
                <v-icon class="mr-2 text-white">mdi-facebook</v-icon>
                <a :href="facebook" target="_blank">{{facebook}}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="twitter">
                <v-icon class="mr-2 text-white">mdi-twitter</v-icon>
                <a :href="twitter" target="_blank">{{twitter}}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="linkedin">
                <v-icon class="mr-2 text-white">mdi-linkedin</v-icon>
                <a :href="linkedin" target="_blank">{{linkedin}}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="skype">
                <v-icon class="mr-2 text-white">mdi-skype-business</v-icon>
                <a :href="skype" target="_blank">{{skype}}</a>
              </li>
            </ul>
          </div>
            
          <v-btn color="white" max-width="250" height="44" class="d-flex mx-auto fz-16 fw-600 rounded mt-4 text-custom-danger" :href="card_contact" target="_blank" v-if="card_contact">
            <v-icon class="mr-2 fz-24">mdi-plus</v-icon>
            Añadir a contactos
          </v-btn>

          <pre class="border bg-white mt-3" v-if="error">
            {{error}}
          </pre>

          <!-- <v-dialog v-model="dialog_qr[0]" width="290">
            <v-card>
              <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                <h1 class="my-0">Código QR</h1>
                <v-btn icon color="danger" @click="closeQrCode()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <v-card-text class="scroll-modal-quotations">
                <div v-html="qr_code" class="qr-code"></div>
              </v-card-text>
            </v-card>
          </v-dialog> -->

          <!-- <div class="col-12 col-md-10 col-lg-8 mt-3">
            <div class="d-flex align-items-center justify-content-end text-white cursor-pointer">
              <p class="my-0 fw-600 mr-2 fz-16">Añadir a contactos</p>
              <v-btn fab bottom right color="white" small :href="'tel:'+phone_number" target="_blank">
                <v-icon color="custom-danger" class="fz-28">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import ProfileController from '../controllers/ProfileController';
 import File from "../../../core/helpers/File";

export default {
  name: 'ProfileView',
  data() {
    return {
      File,
      Helpers,
      header: 'company',
      contact_id: null,
      photo: '',
      names: '',
      surnames: '',
      position: '',
      phone_number: '',
      address: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      qr_code: '',
      dialog_qr: {},
      template: '',
      card_contact: '',
      error: '',

      profile_controller: null
    };
  },
   computed: {
    getPhotoUrl() {
    console.log('Photo vaswlue:', this.photo);
    return this.photo.startsWith('http') ? this.photo : `https://soyesenttiaqa.life/zfiles/archivos/users/${this.photo}`;
  }
  },

  mounted() {
    console.log('Componente montado. Valor inicial de photo:', this.photo);

    this.profile_controller = new ProfileController(this);
  },

  methods: {
    addContact() {
      this.profile_controller.addContact();
    },
    
    openQrCode() {
      this.profile_controller.openQrCode();
    },

    closeQrCode() {
      this.profile_controller.closeQrCode();
    }
  }
}
</script>