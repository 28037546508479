import "gasparesganga-jquery-loading-overlay";
import $ from "jquery";
import Vue from "vue";

export default class UI {
  static mensajeOk(titulo, msg) {
    Vue.swal.fire({
      icon: 'success',
      title: titulo,
      html: msg,
      showConfirmButton: false,
      timer: 2500,
    });
  }

  static mensajeBad(titulo, msg) {
    Vue.swal.fire({
      icon: 'error',
      title: titulo,
      html: msg,
      showConfirmButton: true,
      confirmButtonText: 'Salir',
    });
  }
  
  static mensajeConfirm(titulo, msg) {
    return Vue.swal.fire({  
      title: titulo,  
      html: msg,
      showDenyButton: true,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',  
      denyButtonText: 'Cancelar',
    });
  }
  
  static loading(mode) {
    if (mode == 'show') {
      $.LoadingOverlay('show');
    } else if (mode == 'hide') {
      $.LoadingOverlay('hide', true);
    }
  }
  static showLoading(message) {
    $.LoadingOverlay('show', {
      text: message,
    });
  }
  
  static hideLoading() {
    $.LoadingOverlay('hide', true);
  }
  
}