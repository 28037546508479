import MainRepository from "../repositories/MainRepository";

class MainService {
  main_repository;

  constructor() {
    this.main_repository = new MainRepository();
  }

  async infoProfile(userId) {
    console.log('MainService.infoProfile llamado con userId:', userId);
    // Si userId es undefined, null o '{userId}', usamos null
    const effectiveUserId = (userId && userId !== '{userId}') ? userId : null;
    console.log('MainService.infoProfile usando effectiveUserId:', effectiveUserId);
    let r = await this.main_repository.infoProfile(effectiveUserId);
    return r;
  }

  async getUserId() {
    try {
      let r = await this.infoProfile('current');
      if (r.success && r.data && r.data.usuario_id) {
        return r.data.usuario_id;
      } else {
        throw new Error('No se pudo obtener el ID del usuario');
      }
    } catch (error) {
      console.error('Error al obtener el ID del usuario:', error);
      throw error;
    }
  }
}

export default MainService;