<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Portafolio</li>
            <li>Gestionar</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Gestionar portafolio</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form class="w-100" ref="form_briefcase" method="post" v-model="form_valid" lazy-validation @submit.prevent="manageBriefcase()">
              <div class="row">
                <div class="col-md-6">
                  <v-text-field filled label="Titulo" v-model="title" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-file-input filled label="Imagen" :prepend-icon="null" @change="uploadFile($event)" v-model="upload_photo" v-if="!image"></v-file-input>

                  <div class="row pb-4" v-if="image">
                    <div class="col-md-8">
                      <img :src="File.fileUrlbrief(image)" class="d-block mx-auto" width="90" alt="Imagen del portafolio" v-if="image">
                    </div>
                    <div class="col-md-4">
                      <v-btn color="danger" @click="deleteImage()">Eliminar</v-btn>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="URL" type="url" v-model="link" :rules="[rules.url, rules.requerido]"></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field filled label="Texto del botón" v-model="link_name" :rules="[rules.requerido]"></v-text-field>
                </div>
                <div class="col-12">
                  <v-textarea filled label="Descripción" v-model="description" :rules="[rules.requerido]"></v-textarea>
                </div>
                <div class="col-md-4" v-if="is_edit">
                  <v-switch v-model="is_active" color="primary" @change="toggleStateBriefcase()" label="Habilitar"></v-switch>
                </div>
              </div>

              <v-btn type="submit" rounded color="primary" min-width="150" class="d-table mx-auto mt-3">Guardar</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import ManageController from '../controllers/ManageController';

export default {
  name: 'ManageBriefcase', // Añadido un nombre de componente multi-palabra

  data() {
    return {
      Helpers,
      File,
      title: '',
      image: '',
      upload_photo: null,
      description: '',
      is_active: false,
      is_edit: false,
      briefcase_id: null,
      link: '',
      link_name: '',

      form_valid: true,
      rules: {
        requerido: value => !!value || 'Campo obligatorio',
        email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email incorrecto',
        url: value => this.validatedUrl(value) || 'URL no válida',
      },

      manage_controller: null
    };
  },

  mounted() {
    this.manage_controller = new ManageController(this);
    console.log("Estado inicial de image:", this.image); // Verifica el estado inicial

  },

  methods: {
    uploadFile(file) {
  const baseUrl = 'https://soyesenttiaqa.life'; // Define la base de la URL

  if (file) {
    File.uploadFile(file, '/briefcases', (response) => {
      if (response && response.ruta_completa) {
        let fullUrl = response.ruta_completa;

        // Si `response.ruta_completa` no incluye el dominio, concatenar con `baseUrl`
        if (!fullUrl.startsWith(baseUrl)) {
          fullUrl = `${baseUrl}${response.ruta_completa}`;
        }

        this.image = fullUrl; // Asignar la URL completa a `this.image`
        console.log("URL procesada de la imagen:", this.image);
      } else {
        console.warn("No se recibió 'ruta_completa' en la respuesta:", response);
      }
    });
  }
}




,

    deleteImage() {
      this.manage_controller.deleteImage();
    },

    manageBriefcase() {
      this.manage_controller.manageBriefcase();
    },

    toggleStateBriefcase() {
      this.manage_controller.toggleStateBriefcase();
    },

    validatedUrl(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
  }
}
</script>