import ManageRepository from "../repositories/ManageRepository";

class ManageService {
  manage_repository;

  constructor() {
    this.manage_repository = new ManageRepository();
  }

  async createBriefcase(title, image, description, link, link_name) {
    let r = await this.manage_repository.createBriefcase(title, image, description, link, link_name);
    return r;
  }

  async updateBriefcase(briefcase_id, title, image, description, link, link_name) {
    let r = await this.manage_repository.updateBriefcase(briefcase_id, title, image, description, link, link_name);
    return r;
  }

  async infoBriefcase(briefcase_id) {
    let r = await this.manage_repository.infoBriefcase(briefcase_id);
    return r;
  }

  async toggleStateBriefcase(briefcase_id) {
    let r = await this.manage_repository.toggleStateBriefcase(briefcase_id);
    return r;
  }
}

export default ManageService;