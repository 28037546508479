import LectorRepository from "../repositories/LectorRepository";

class LectorService {
  lector_repository;

  constructor() {
    this.lector_repository = new LectorRepository();
  }

  async filterCollaborators(search) {
    let r = await this.lector_repository.filterCollaborators(search);
    return r;
  }
}

export default LectorService;