import PaginatorService from "../services/PaginatorService";
import ErrorManager from "../../../../libs/ServiceResponse/ErrorManager";
import UI from  '../../../../helpers/UI';
import Helpers from "../../../../helpers/Helpers";

class PaginatorExtendedController {
  context;
  paginator_service;

  constructor(context) {
    this.context = context;
    this.paginator_service = new PaginatorService();
    this._getTableData();
    setTimeout(() => {
      this.configureButtons();      
    }, 2000);
  }

  readDataFromAPIExtended() {
    if(this.context.canGetData){
      UI.loading('show');
      this.context.loading = true;
      this.context.canGetData = false;
      const { page, itemsPerPage, sortBy, sortDesc } = this.context.options;
      let sortByValue = sortBy[0] ? sortBy[0] : '';
      let page_number = page == 0 ? 1 : page;
      let query_params = "?page="+ page_number +"&limit="+ itemsPerPage;
      query_params += "&columsort="+ sortByValue +"&sortdesc="+ sortDesc +"&search="+ this.context.search;
      if(this.context.filters_str != ''){
        query_params += this.context.filters_str;
      }
      this.paginator_service.readDataFromAPIExtended(this.context.endpoint, query_params)
      .then((r) => {
        if(r.success == true) {
          this.context.loading = false;
          this.context.canGetData = true;
          this.context.passengers = r.data.items;
          this.context.totalPassengers = r.data.total;
          this.context.numberOfPages = r.data.total_pages;
          UI.loading('hide');
        }
      })
      .catch((e) => new ErrorManager(e, () => {
        this.context.loading = false;
        this.context.canGetData = true;
        UI.loading('hide');
      }));
    }
  }

  configureButtons(){
    console.log('config_buttons',this.context.config_buttons);
    if(this.context.config_buttons.length > 0 && !this.validateColumnActions()){
      this.context.headers.push({ text: 'Acciones', value: 'actions', sortable: false });
    }
  }

  validateColumnActions(){
    for (let i = 0; i < this.context.headers.length; i++) {
      const e = this.context.headers[i];
      if(e.value == 'actions'){
        return true;
      }
    }
    return false;
  }

  configureButtonUrl(item_entity, item_button){
    let url = item_button.url;
    for (let param of item_button.params_url) {
        url = url.replace("["+param+"]", item_entity[param]);
    }
    return Helpers.urlapplink(this.context.applicative, url);
  }

  deleteFromAPI(endpoint, id, type_petition) {
    UI.loading('show');
    this.paginator_service.deleteFromAPI(endpoint, id, type_petition)
    .then((r) => {
      if(r.success == true) {
        this.readDataFromAPIExtended();
        UI.mensajeOk('', r.msg);        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  filterData(){
    this.context.filters_str = this.context.$refs['filter_table_extended'].getValue();
    this.readDataFromAPIExtended();
  }

  _getTableData(){
    if(this.context.mockup_data && this.context.mockup_data.success){
      console.log('tabla', 'mockup');
      this.context.loading = false;
      this.context.canGetData = true;
      this.context.passengers = this.context.mockup_data.data.items;
      this.context.totalPassengers = this.context.mockup_data.data.total;
      this.context.numberOfPages = this.context.mockup_data.data.total_pages;
    }else{
      console.log('tabla', 'api');
      this.readDataFromAPIExtended();
    }
  }

}

export default PaginatorExtendedController;