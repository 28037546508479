import Rest from "../../../libs/Rest";

class PieChartRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  getDataChart(endpoint) {
    this.rest.setParams('');
    this.rest.setEndPoint(endpoint);
    return this.rest.get();
  }
}

export default PieChartRepository;