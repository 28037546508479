import ListRepository from "../repositories/ListRepository";

class ListService {
  list_repository;

  constructor() {
    this.list_repository = new ListRepository();
  }

  async listUsers() {
    let r = await this.list_repository.listUsers();
    return r;
  }

  async toggleLockUser(usuario_id) {
    let r = await this.list_repository.toggleLockUser(usuario_id);
    return r;
  }
}

export default ListService;