import ServiceResponseExeption from "./ServiceResponseExeption";

class ServiceResponse {

    status = false;
    data = {};
    message = "";

    constructor(status, data, message = ''){
        this.status = status;
        this.data = data;
        this.message = message;
    }

    getData(){
        return this.data;
    }
    
    toPromise(){
        return new Promise((result) => {
            if(this.status){
                result({res:true, data:this.data});
            }else{
                throw new ServiceResponseExeption(this.message);
            }
        });
    }
}

export default ServiceResponse;