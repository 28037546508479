import PaginatorRepository from "../repositories/PaginatorRepository";

class PaginatorService {
  paginator_repository;
  
  constructor() {
    this.paginator_repository = new PaginatorRepository();
  }

  async readDataFromAPISimple(endpoint) {
    let response_service = await this.paginator_repository.readDataFromAPISimple(endpoint);
    return response_service;
  }

  async readDataFromAPIExtended(endpoint, query_params) {
    let response_service = await this.paginator_repository.readDataFromAPIExtended(endpoint, query_params);
    return response_service;
  }

  async deleteFromAPI(endpoint, id) {
    let response_service = await this.paginator_repository.deleteFromAPI(endpoint, id);
    return response_service;
  }
}

export default PaginatorService;