import Rest from '../../../core/libs/Rest';
// import { LIST_MOCKUP } from '../mockups/List';

class ListRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listUsers() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/contact/paginate-all');
    return this.rest.get();
  }

  toggleLockUser(usuario_id) {
    this.rest.setParams('');
    this.rest.setEndPoint('api/card/contact/toggle-state-contact');
    return this.rest.patch(usuario_id);
  }
}

export default ListRepository;