<template>
    <div>
        <filters-view
            :filters="filters"
            ref="filter_table_extended"
        ></filters-view>
        <div class="text-center" v-if="filters.length > 0">
            <v-btn color="primary" type="button" x-large @click="filterData">
                <v-icon class="mr-2">mdi-magnify</v-icon>
                Buscar
            </v-btn>
        </div>
        <br>
        <v-data-table
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :headers="headers"
            :items="passengers"
            :options.sync="options"
            :server-items-length="totalPassengers"
            :loading="loading"
            class="elevation-1"
        >
            <template #top>
                <v-text-field
                    v-model="search"
                    label="Buscar"
                    filled
                ></v-text-field>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-tooltip bottom v-for="(itemButton, index) in config_buttons" :key="index">
                    <template #activator="{ on, attrs }">
                        <v-btn 
                            v-if="!itemButton.webservice.endpoint"
                            icon 
                            :color="itemButton.color" 
                            v-bind="attrs" 
                            v-on="on"
                            :href="configureButtonUrl(item, itemButton)" 
                            :target="itemButton.new_tab ? '_blank' : ''"
                        >
                            <v-icon>{{ itemButton.icon }}</v-icon>
                        </v-btn>
                        <v-btn 
                            v-else
                            icon 
                            :color="itemButton.color" 
                            v-bind="attrs" 
                            v-on="on"
                            @click="deleteFromAPI(item, itemButton)"
                        >
                            <v-icon>{{ itemButton.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ itemButton.label }}</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Helpers from '../../../../helpers/Helpers';
import PaginatorExtendedController from '../controllers/PaginatorExtendedController';

export default {
    name: 'TablePaginatorExtended',
    props: {
        headers: Array,
        endpoint: String,
        config_buttons: Array,
        filters: Array,
        applicative: String,
        mockup_data: [Array, Object]
    },
    data() {
        return {
            Helpers,
            page: 1,
            itemsPerPage: 10,
            totalPassengers: 0,
            passengers: [],
            loading: true,
            canGetData: true,
            options: {},
            search: '',
            filters_str: '',
            paginator_controller: null
        };
    },
    mounted() {
        this.paginator_controller = new PaginatorExtendedController(this);
    },
    watch: {
        options: {
            handler() {
                this.paginator_controller.readDataFromAPIExtended();
            },
            deep: true
        },
        search: {
            handler(newValue) {
                if (newValue.length > 2 || newValue.length === 0) {
                    this.debouncedReadData();
                }
            }
        }
    },
    created() {
        this.debouncedReadData = this.debounce(this.paginator_controller.readDataFromAPIExtended, 2000);
    },
    methods: {
        configureButtonUrl(itemEntity, itemButton) {
            return this.paginator_controller.configureButtonUrl(itemEntity, itemButton);
        },
        deleteFromAPI(itemEntity, itemButton) {
            return this.paginator_controller.deleteFromAPI(
                itemButton.webservice.endpoint,
                itemEntity[itemButton.webservice.param],
                itemButton.webservice.type_petition
            );
        },
        filterData() {
            this.paginator_controller.filterData();
        },
        debounce(fn, delay) {
            let timeoutId;
            return function (...args) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => fn.apply(this, args), delay);
            };
        }
    },
}
</script>