<template>
    <div>
        <section class="main">
            <div class="container-fluid main-box-content contentModulo">
                <h1 class="my-0 text-standar fz-30">Jobs</h1>

                <div class="breadcrumb p-0 rounded-0 mt-2 mt-lg-3 mb-3 mb-sm-4">
                    <ul>
                        <li>Inicio</li>
                        <li>Desarrolladores</li>
                        <li>Jobs</li>
                    </ul>
                </div>

                <div class="row">
                    <div class="col-sm-4" style="margin-bottom: 15px !important;">
                        <v-card>
                            <v-card-subtitle>Agendar tareas</v-card-subtitle>
                            <v-card-text class="py-lg-4">
                                <div class="text-center">
                                    <button type="button" class="btn btn-success mx-auto my-3 mt-sm-0" @click="scheduleJobs()">Agendar</button>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="col-sm-4" style="margin-bottom: 15px !important;">
                        <v-card>
                            <v-card-subtitle>Procesar tareas</v-card-subtitle>
                            <v-card-text class="py-lg-4">
                                <div class="text-center">
                                    <button type="button" class="btn btn-success mx-auto my-3 mt-sm-0" @click="processJobs()">Procesar</button>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="col-sm-4" style="margin-bottom: 15px !important;">
                        <v-card>
                            <v-card-subtitle>Procesar tareas version 2</v-card-subtitle>
                            <v-card-text class="py-lg-4">
                                <div class="text-center">
                                    <button type="button" class="btn btn-success mx-auto my-3 mt-sm-0" @click="processJobsV2()">Procesar</button>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import JobController from '../controllers/JobController';

export default {
    name: 'JobManagement', // Cambiar el nombre del componente a algo más descriptivo
    data(){
        return {
            job_controller: null
        }
    },
    mounted() {
        this.job_controller = new JobController(this);
    },
    methods:{
        scheduleJobs(){
            this.job_controller.scheduleJobs();
        },
        processJobs(){
            this.job_controller.processJobs();
        },
        processJobsV2(){
            this.job_controller.processJobsV2();
        }
    }
}
</script>
