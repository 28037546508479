import ServiceResponse from "../../../libs/ServiceResponse/ServiceResponse";
import JobRepository from "../repositories/JobRepository";
import UI from  '../../../helpers/UI';

class JobService {
  context;
  job_repository = null;

  constructor(context) {
    this.context = context;
    this.job_repository = new JobRepository();
  }

  async scheduleJobs(frequency) {
    let response_service = await this.job_repository.scheduleJobs(frequency);
    if (!response_service.success) {      
      return new ServiceResponse(false, {}, response_service.msg).toPromise();
    }
    UI.mensajeOk('JOBS',response_service.msg);
    return new ServiceResponse(true, response_service.data).toPromise();
  }

  async processJobs() {
    let response_service = await this.job_repository.processJobs();
    if (!response_service.success) {
      return new ServiceResponse(false, {}, response_service.msg).toPromise();
    }
    UI.mensajeOk('JOBS',response_service.msg);
    return new ServiceResponse(true, response_service.data).toPromise();
  }

  async processJobsV2() {
    let response_service = await this.job_repository.processJobsV2();
    if (!response_service.success) {
      return new ServiceResponse(false, {}, response_service.msg).toPromise();
    }
    UI.mensajeOk('JOBS',response_service.msg);
    return new ServiceResponse(true, response_service.data).toPromise();
  }
  
}

export default JobService;