import Rest from "../../../libs/Rest";

class JobRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  scheduleJobs(frequency) {
    this.rest.setParams('/'+frequency);
    this.rest.setEndPoint('api/core/jobs-run-cronjobs-mode-developer');
    return this.rest.get();
  }

  processJobs() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/core/jobs-procesar-colas');
    return this.rest.get();
  }

  processJobsV2() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/core/jobs-procesar-colas-v2');
    return this.rest.get();
  }
}

export default JobRepository;