<template>
    <div>
        <div class="row text-center">
            <div v-for="(item, index) in filters" :key="index" class="col-sm-3">
                <v-text-field
                    v-if="item.type === 'TEXT'"
                    v-model="item.model"
                    :label="item.label"
                    :ref="`ref_${item.ref}`"
                    filled
                    clearable
                ></v-text-field>
                <v-dialog 
                    v-else-if="item.type === 'DATE'" 
                    :ref="`dialog_${item.ref}`" 
                    v-model="item.type_date_option.modal_init" 
                    :return-value.sync="item.model" 
                    persistent 
                    width="290px"
                >
                    <template #activator="{ on, attrs }">
                        <v-text-field 
                            v-model.lazy="item.model" 
                            :label="item.label" 
                            readonly 
                            filled 
                            clearable 
                            v-bind="attrs" 
                            v-on="on"                            
                            :ref="`ref_${item.ref}`"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="item.model" scrollable locale="es" color="primary">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="item.type_date_option.modal_init = false">
                            Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="$refs[`dialog_${item.ref}`][0].save(item.model)">
                            Seleccionar
                        </v-btn>
                    </v-date-picker>
                </v-dialog>                
                <filter-select
                    v-else-if="item.type === 'SELECT'"
                    :filter_info="item"
                    :ref="`ref_${item.ref}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Helpers from '../../../../helpers/Helpers';
// import FilterSelectController from '../controllers/FilterSelectController';

export default {
    name: 'FiltersView',
    props: {
        filters: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            Helpers,
            filter_controller: null,
            filter_select_controller: null
        };
    },
    mounted() {
        // this.filter_controller = new FilterSelectController(this);
    },
    methods: {
        getValue() {
            const filters_array = {};
            this.filters.forEach((v) => {
                const ref = `ref_${v.ref}`;
                let value_ref = '';
                if (this.$refs[ref] && this.$refs[ref][0]) {
                    if (!this.$refs[ref][0].$refs.input) {
                        value_ref = this.$refs[ref][0].getValue();
                    } else {
                        value_ref = this.$refs[ref][0].$refs.input._value;
                    }
                    filters_array[v.ref] = Helpers.validateValue(value_ref);
                }
            });
            return `&filter=${JSON.stringify(filters_array)}`;
        }
    },
}
</script>