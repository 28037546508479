import Enviroment from "./Enviroment";

export default class Helpers{

    static getBaseUrl(){
        if(Enviroment.getBaseUrl()){
            return Enviroment.getBaseUrl();
        }else{
            if(window.location.port){
                return window.location.protocol+'//'+ window.location.hostname +':'+ window.location.port + '/';
            }else{
                return window.location.protocol+'//'+ window.location.hostname +'/';
            }
        }
        
    }

    static getApiUrl(){
      return Enviroment.getUrlEndpoint();
    }

    static urlmodulo(modulo,link){
        if(Enviroment.getBaseUrl()){
            return Enviroment.getBaseUrl() + '#/'+ modulo +'/'+ link;
        }else{
            return Helpers.getBaseUrl() + '#/'+ modulo +'/'+ link;
        }
    }

    static urlappmodulo(applicative,modulo,link){
        if(Enviroment.getBaseUrl()){
            return Enviroment.getBaseUrl() + '#/'+ modulo +'/'+ link;
        }else{
            if(Enviroment.getAppEnv() == 'local'){
                return Helpers.urlmodulo(modulo,link);
            }
            return Helpers.getBaseUrl() + applicative + '/#/'+ modulo +'/'+ link;
        }
    }

    static urlapplink(applicative,link){
        if(Enviroment.getBaseUrl()){
            return Enviroment.getBaseUrl() + '#/'+ link;
        }else{
            if(Enviroment.getAppEnv() == 'local'){
                return Helpers.getBaseUrl() + '#/'+ link;
            }
            return Helpers.getBaseUrl() + applicative + '/#/'+ link;
        }
    }

    static isLocal(){
        if(window.location.href.indexOf('heaven.') || window.location.href.indexOf('localhost')){
            return true;
        }
        return false;
    }

    static urlInterna(lugar){
        return Helpers.getBaseUrl() + lugar;
    }

    static getFullUrl(){
        return window.location.href;
    }

    static isLogin(){
        if(Helpers.getFullUrl().indexOf('login/ingresar') > -1){
            return true;
        }else{
            return false;
        }
    }

    static isModule(module){
        if(Helpers.getFullUrl().indexOf(module) > -1){
            return true;
        }else{
            return false;
        }
    }

    static reload(_this){
        _this.$emit('recargapage',{});
    }

    static isFloat(n){
        var num = /^[-+]?[0-9]+\.[0-9]+$/;
        return num.test(n);
    }

    static isEmpty(valor){
        if(valor == null || valor == 'undefinded' || valor == ''){
            return true;
        }else{
            return false;
        }
    }

    static validateValue(valor){
        if(valor == null || valor == 'undefinded' || valor == '' || valor == 'null'){
            return '';
        }
        return valor;
    }
    //test
    static validateValueMax(value, length){
        if(!value){
            return true;
        }
        if(value.toString().length <= length){
            return true;
        }
        return false;
    }

    static getMonthInText(month){
        if(!month && month != 0){
            month = new Date().toISOString().substr(0, 7).slice(5);
        }
        let months = [];
        months.push('Enero');
        months.push('Febrero');
        months.push('Marzo');
        months.push('Abril');
        months.push('Mayo');
        months.push('Junio');
        months.push('Julio');
        months.push('Agosto');
        months.push('Septiembre');
        months.push('Octubre');
        months.push('Noviembre');
        months.push('Diciembre');
        return months[month -1];
    }

    static messageResult(response){
        console.log('response', response);
        if(response.code == 400){
            return 'Petición incorrecta. ';
        }else if(response.code == 401){
            return this.messageResult401(response);
        }else if(response.code == 200){            
            return response.msg;
        }
        return 'Error: '+ response.msg;
    }

    static messageResult401(messages){
        //console.log('messages', messages);
        if(!Array.isArray(messages.data) && (typeof messages.data) == "string"){
            return messages.data;
        }
        if(messages.data.length == 0){
            return messages.msg;
        }
        let item = "<strong>Han ocurrido errores*. </strong><br><br>";
        item += '<ul style="text-align: left;">';
        if(messages.data[0]){
            for (let i = 0; i < messages.data.length; i++) {
                const m = messages.data[i];
                item += '<li>'+ m +'</li>';
            }
        }else{
            for (const key in messages.data) {
                if (Object.hasOwnProperty.call(messages.data, key)) {
                    const element = messages.data[key];
                    for (let i = 0; i < element.length; i++) {
                        const m = element[i];
                        item += '<li><strong>'+key+'. </strong>'+ m +'</li>';
                    }
                }
            }
        }        
        item += '</ul>';
        return item;
    }
}
