import FilterRepository from "../repositories/FilterRepository";

class FilterService {
  filter_repository;
  
  constructor() {
    this.filter_repository = new FilterRepository();
  }

  async readDataFromAPI(endpoint) {
    let response_service = await this.filter_repository.readDataFromAPI(endpoint);
    return response_service;
  }
}

export default FilterService;