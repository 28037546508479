<template>
    <div>

        <div class="px-3">
          <v-card>
            <v-card-title>
              {{title_view}}
            </v-card-title>
            <v-card-text>

              <div class="table-responsive mt-4">
                <table-paginator-view
                    v-if="tp_loaded_data"
                    :headers="tp_headers"
                    :endpoint="tp_endpoint"
                    :config_buttons="tp_config_buttons"
                    :filters="tp_filters"
                    :mockup_data="tp_mockup_data"
                    @click="handleClick"
                ></table-paginator-view>
                <br><br>
              </div>

            </v-card-text>
          </v-card>
        </div>

    </div>
</template>

<script>
import Helpers from '../../../../helpers/Helpers';
import DynamicViewController from '../controllers/DynamicViewController';
export default {
    props: ['title_view','endpoint','module','type', 'submodule', 'view_name', 'mockup_data'],
    data: () => {
        return {
            Helpers,
            tp_headers: [],
            tp_endpoint: '',
            tp_config_buttons: [],
            tp_filters: [],
            tp_mockup_data: [],
            tp_loaded_data: false,

            dynamic_view_controller: null
        };
    },
    mounted(){
      this.dynamic_view_controller = new DynamicViewController(this);
    },
    methods: {
      getView(){
          this.dynamic_view_controller.getView();
      },
      handleClick(info) {
        this.$emit("click", info);
      }
    },
}
</script>