import Principal from '../../core/layouts/Principal.vue';
import Update from './views/Update.vue';

window.routes.push({
  path: '/company',
  name: 'company',
  component: Principal,
  children: [
    {
      path: 'update',
      name: 'update',
      component: Update,
    },
  ],
});