
import Enviroment from "../../../helpers/Enviroment";
import Session from "../../../helpers/Session";
import UI from "../../../helpers/UI";
import MenuService from "../services/MenuService";

class MenuController {
  context;
  menu_service;

  constructor(context) {
    this.context = context;
    // window.menu

    this.menu_service = new MenuService();
    this._printMenu();
  }

  _printMenu(){
    let tipo_menu = 'LOCAL_FILE';
    console.log('tipo_menu', tipo_menu);
    if(tipo_menu == 'LOCAL_FILE'){
      let informacion_menu = window.menu;
      this.context.menu = informacion_menu;
    }else{
      let menu_user = Session.get('menu_user');
      if(!menu_user){
        this._getMenuGeneral();
      }else{
        this.context.menu = JSON.parse(menu_user);
      }
    }
  }

  _getMenuGeneral() {
    UI.loading('show');
    this.menu_service.getMenuGeneral()
    .then((r) => {
      if(r.res == true) {
        this.context.menu = r.data;
        Session.set('menu_user', JSON.stringify(this.context.menu));
        UI.loading('hide');
      }
    }).catch((e) => {
      console.log('ERROR', e);
      console.log('NO SE ENCONTRO MENU');
      //UI.mensajeBad('', e.msg);
      let informacion_menu = window.menu;
      this.context.menu = informacion_menu;
      UI.loading('hide');
    });
  }
}

export default MenuController;