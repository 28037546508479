import UI from  '../../../core/helpers/UI';
// import File from "../../../core/helpers/File";
import BriefcaseService from '../services/BriefcaseService';

class BriefcaseController {
  context;
  briefcase_service;

  constructor(context) {
    this.context = context;
    this.briefcase_service = new BriefcaseService();

    if(this.context.$route.query.id) {
      this.context.contact_id = parseInt(this.context.$route.query.id);
    }

    this._listBriefcases();
  }

  _listBriefcases() {
    UI.loading('show');
    this.briefcase_service.listBriefcases()
    .then((r) => {
      if(r.success == true) {
        if(r.data && r.data.length > 0) {
          r.data.forEach((v) => {
            if(v.state == 1) {
              this.context.list_briefcases.push({
                ...v,
                show_more: v.description.length > 158 ? true : false,
              });
            }
          });
        }
        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  toggleText(position) {
    this.context.list_briefcases[position].show_more = !this.context.list_briefcases[position].show_more;
  }
}

export default BriefcaseController;