<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="passengers"
            :search="search"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
        >
            <template #top>
                <v-text-field
                    v-model="search"
                    label="Buscar"
                    filled
                ></v-text-field>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-tooltip bottom v-for="(itemButton, index) in config_buttons" :key="index">
                    <template #activator="{ on, attrs }">
                        <v-btn 
                            v-if="!itemButton.webservice.endpoint"
                            icon 
                            :color="itemButton.color" 
                            v-bind="attrs" 
                            v-on="on"
                            :href="configureButtonUrl(item, itemButton)"
                        >
                            <v-icon>{{ itemButton.icon }}</v-icon>
                        </v-btn>
                        <v-btn 
                            v-else
                            icon 
                            :color="itemButton.color" 
                            v-bind="attrs" 
                            v-on="on"
                            @click="deleteFromAPI(item, itemButton)"
                        >
                            <v-icon>{{ itemButton.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ itemButton.label }}</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Helpers from '../../../../helpers/Helpers';
import PaginatorSimpleController from '../controllers/PaginatorSimpleController';

export default {
    name: 'TablePaginatorSimple',
    props: {
        headers: Array,
        endpoint: String,
        config_buttons: Array
    },
    data() {
        return {
            Helpers,
            passengers: [],
            loading: true,
            search: '',
            paginator_controller: null
        };
    },
    mounted() {
        this.paginator_controller = new PaginatorSimpleController(this);
    },
    methods: {
        configureButtonUrl(itemEntity, itemButton) {
            return this.paginator_controller.configureButtonUrl(itemEntity, itemButton);
        },
        deleteFromAPI(itemEntity, itemButton) {
            return this.paginator_controller.deleteFromAPI(
                itemButton.webservice.endpoint,
                itemEntity[itemButton.webservice.param],
                itemButton.webservice.type_petition
            );
        }
    },
}
</script>