import UI from  '../../../core/helpers/UI.js';
import File from "../../../core/helpers/File";
import ManageService from '../services/ManageService';

class ManageController {
  context;
  manage_service;
  valid;
  is_valid;
  

  constructor(context) {
    this.context = context;
    this.manage_service = new ManageService();

    this.context.type_id_list = [
      {
        name: 'Cédula de ciudadanía',
        type_id: 'CC',
      },
      {
        name: 'Cédula de extranjería',
        type_id: 'CE',
      },
      {
        name: 'Tarjeta de identidad',
        type_id: 'TI',
      },
      {
        name: 'Pasaporte',
        type_id: 'PA',
      },
      {
        name: 'NIT',
        type_id: 'NIT',
      },
      {
        name: 'NIT Extranjería',
        type_id: 'NIE',
      },
      {
        name: 'Persona Jurídica Extranjera',
        type_id: 'PJE',
      },
    ];

    this.context.list_created_card = [
      { value: 1, text: 'Si' },
      { value: 2, text: 'No' },
    ];

    this.context.type_user_list = [
      {
        //tipo_id: 'adm',
        tipo_id: 4,
        nombre: 'Administrador'
      },
      {
        //tipo_id: 'empl',
        tipo_id: 6,
        nombre: 'Colaborador'
      }
    ];

    this.context.is_edit = false;

    if(this.context.$route.params.id) {
      this.context.user_id = parseInt(this.context.$route.params.id);
      this.context.is_edit = true;
      this._infoUser();
    }

    //this._listTypeUsers();
    this._listPositionsUsers();

    console.log("Llamando a _listPositionsUsers desde el constructor");

    this._listTemplates();
  }

  _listTypeUsers() {
    this.context.type_user_list = [];
    UI.loading('show');
    this.manage_service.listTypeUsers()
    .then((r) => {
      if(r.success == true) {
        this.context.type_user_list = r.data;

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  _listPositionsUsers() {
    console.log("Iniciando _listPositionsUsers");
    this.context.position_list = [];
    UI.loading('show');
    this.manage_service.listPositionsUsers()
    .then((r) => {
      console.log("Respuesta de listPositionsUsers:", r);
      if(r.success == true) {
        this.context.position_list = r.data;
        console.log("position_list actualizada:", this.context.position_list);
        UI.loading('hide');
      }
    }).catch((e) => {
      console.error("Error en listPositionsUsers:", e);
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  _listTemplates() {
    this.context.list_templates = [];
    UI.loading('show');
    this.manage_service.listTemplates()
    .then((r) => {
      if(r.success == true) {
        this.context.list_templates = r.data;

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  _infoUser() {
    UI.loading('show');
    this.manage_service.infoUser(this.context.user_id)
    .then((r) => {
      if(r.success == true) {
        this.context.user_id = r.data.usuario_id;
        this.context.first_name = r.data.pri_nombre != null && r.data.pri_nombre != 'null' ? r.data.pri_nombre : '';
        this.context.second_name = r.data.seg_nombre != null && r.data.seg_nombre != 'null' ? r.data.seg_nombre : '';
        this.context.first_surname = r.data.pri_apellido != null && r.data.pri_apellido != 'null' ? r.data.pri_apellido : '';
        this.context.second_surname = r.data.seg_apellido != null && r.data.seg_apellido != 'null' ? r.data.seg_apellido : '';
        this.context.type_id = r.data.tipo_doc;

        if(r.data.documento && r.data.documento != null && r.data.documento != 'null') {
          this.context.number_id = r.data.documento;
        }

        this.context.type_user = r.data.tipo_id;
        this.context.email = r.data.email;
        this.context.position = r.data.position_id;
        this.context.address = r.data.address;

        if(r.data.foto && r.data.foto != null && r.data.foto != 'null') {
          this.context.photo = r.data.foto;
        }

        if(r.data.template_id && r.data.template_id != null && r.data.template_id != 'null') {
          this.context.template = r.data.template_id;
        }

        // if(r.data.qr_code) {
        //   this.context.show_card = '1';
        // } else {
        //   this.context.show_card = '2';
        // }

        this.context.show_card = '1';

        this.context.website = r.data.website;
        this.context.facebook = r.data.facebook;
        this.context.instagram = r.data.instagram;
        this.context.twitter = r.data.twitter;
        this.context.linkedin = r.data.linkedin;
        this.context.skype = r.data.skype;

        if(r.data.telefono && r.data.telefono != null && r.data.telefono != 'null') {
          this.context.phone_number = r.data.telefono;
        }

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  manageUser() {
    if(this.context.is_edit) {
      this._editUser();
    } else {
      this._createUser();
    }
  }

  _createUser() {
    this.valid = true;

    if(this.context.password != this.context.re_password) {
      UI.mensajeBad('', 'Comprueba que las contraseñas sean iguales.');
      this.valid = false;
    }

    if(this.context.website && !this.validatedUrl(this.context.website)) {
      UI.mensajeBad("", "Formato de URL de sitio web incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.facebook && !this.validatedUrl(this.context.facebook)) {
      UI.mensajeBad("", "Formato de URL de Facebook incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.instagram && !this.validatedUrl(this.context.instagram)) {
      UI.mensajeBad("", "Formato de URL de Instagram incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.twitter && !this.validatedUrl(this.context.twitter)) {
      UI.mensajeBad("", "Formato de URL de Twitter incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.linkedin && !this.validatedUrl(this.context.linkedin)) {
      UI.mensajeBad("", "Formato de URL de Linkedin incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.valid && this.context.$refs.form_user.validate() && this.context.photo) {
      UI.loading('show');
      this.manage_service.createUser(
        this.context.first_name,
        this.context.second_name,
        this.context.first_surname,
        this.context.second_surname,
        this.context.type_id,
        this.context.number_id,
        this.context.type_user,
        this.context.email,
        this.context.position,
        this.context.password,
        this.context.re_password,
        this.context.photo,
        this.context.template,
        this.context.show_card,
        this.context.website,
        this.context.facebook,
        this.context.instagram,
        this.context.twitter,
        this.context.linkedin,
        this.context.skype,
        this.context.phone_number,
        this.context.address
      ).then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.context.$refs.form_user.reset();
          this.deletePhoto();
          window.location = '#/users/list';
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    } else if(this.valid && this.context.$refs.form_user.validate() && !this.context.photo) {
      UI.mensajeBad('', 'Foto de perfil obligatoria.');
    }
  }

  _editUser() {
    this.valid = true;

    if(this.context.password != this.context.re_password) {
      UI.mensajeBad('', 'Comprueba que las contraseñas sean iguales.');
      this.valid = false;
    }

    if(this.valid && this.context.$refs.form_user.validate()) {
      UI.loading('show');
      this.manage_service.editUser(
        this.context.user_id,
        this.context.first_name,
        this.context.second_name,
        this.context.first_surname,
        this.context.second_surname,
        this.context.type_id,
        this.context.number_id,
        this.context.type_user,
        this.context.email,
        this.context.position,
        this.context.password,
        this.context.re_password,
        this.context.photo,
        this.context.template,
        this.context.show_card,
        this.context.website,
        this.context.facebook,
        this.context.instagram,
        this.context.twitter,
        this.context.linkedin,
        this.context.skype,
        this.context.phone_number,
        this.context.address
      ).then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.context.$refs.form_user.reset();
          this.deletePhoto();
          window.location = '#/users/list';
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  uploadPhoto(file) {
    if(file) {
      File.uploadFile(file, '/users', (r)=> {
        this.context.photo = r.data.rutacuenta_encode_token;
      });
      
      setTimeout(() => {
        this.context.upload_photo = null;
      }, 250);
    }
  }

  deletePhoto() {
    this.context.photo = '';
    this.context.upload_photo = null;
  }

  validatedUrl(str) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
  async managePassword() {
    console.log('3. managePassword en Controller llamado');
    console.log('user_id:', this.context.user_id);
    console.log('password:', this.context.password);
    console.log('re_password:', this.context.re_password);

    if (this.context.password !== this.context.re_password) {
      console.log('4. Las contraseñas no coinciden');
      UI.mensajeBad('', 'Las contraseñas deben ser iguales.');
      return;
    }

    if (!this.context.password || !this.context.re_password) {
      console.log('4. Campos de contraseña vacíos');
      UI.mensajeBad('', 'Ambos campos de contraseña son obligatorios.');
      return;
    }

    UI.loading('show');
    try {
      console.log('5. Llamando a manage_service.managePassword');
      const r = await this.manage_service.managePassword(
        this.context.user_id,
        this.context.password,
        this.context.re_password
      );
      console.log('8. Respuesta recibida del servicio:', r);
      
      if (r.success === true) {
        console.log('9. Actualización exitosa');
        UI.mensajeOk('', r.msg || 'Contraseña actualizada con éxito.');
        this.context.resetForm();
      } else {
        console.log('9. Actualización fallida:', r.msg);
        UI.mensajeBad('', r.msg || 'Error al actualizar la contraseña.');
      }
    } catch (e) {
      console.error('9. Error capturado:', e);
      UI.mensajeBad('', e.msg || 'Error en la conexión. Por favor, intenta de nuevo.');
    } finally {
      UI.loading('hide');
    }
  }
}

export default ManageController;