import DetailRepository from "../repositories/DetailRepository";

class DetailService {
  detail_repository;

  constructor() {
    this.detail_repository = new DetailRepository();
  }

  async infoUser(user_id) {
    let r = await this.detail_repository.infoUser(user_id);
    return r;
  }
}

export default DetailService;