class SessionUser {

  isLogued() {
    let status_token = this.getToken();
    let mode_token = this.getModeToken();

    if(status_token && mode_token == 'USER') {
      return true;
    } else {
      return false;
    }
  }

  setModeToken(mode) {
    localStorage.setItem('mode_token', mode);
  }

  getModeToken() {
    return localStorage.getItem('mode_token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setImageProfile(photo) {
    localStorage.setItem('image_profile', photo);
  }

  getImageProfile() {
    return localStorage.getItem('image_profile');
  }

  setNamePublic(name) {
    localStorage.setItem('name', name);
  }

  getNamePublic() {
    return localStorage.getItem('name');
  }

  setSlugUser(slug) {
    localStorage.setItem('slug', slug);
  }

  getSlugUser() {
    return localStorage.getItem('slug');
  }

  setTypeUser(type_user) {
    localStorage.setItem('type_user', type_user);
  }

  getTypeUser() {
    return localStorage.getItem('type_user');
  }

  setUserId(usuario_id) {
    localStorage.setItem('usuario_id', usuario_id);
  }

  getUserId(){
    return localStorage.getItem("usuario_id");
  }

  destroyAll() {
    localStorage.setItem('token_renew', '');
    localStorage.setItem('mode_token', '');
    localStorage.setItem('token', '');
    localStorage.setItem('image_profile', '');
    localStorage.setItem('name', '');
    localStorage.setItem('slug', '');
    localStorage.setItem('type_user', '');
    localStorage.setItem('usuario_id', '');
    localStorage.setItem('menu_user', '');
  }

  destroyToken() {
    localStorage.setItem('token_renew', '');
    localStorage.setItem('mode_token', '');
    localStorage.setItem('token', '');
  }
}

export default SessionUser;