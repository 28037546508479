<template>
  <div>
    <br>
    <br>
    <br>
    <br>
    Lorem ipsum dolor sit amet consectetur. <h1>Start</h1> adipisicing elit. Dicta inventore repudiandae dignissimos soluta molestiae cum neque quae provident animi vero temporibus debitis sapiente, perspiciatis labore est quisquam ipsum qui id?
  </div>
</template>

<script>
export default {
  name: 'WelcomePage', // Actualiza aquí el nombre del componente
  data() {
    return {
    };
  },

  mounted() {
    // Verificar si la página ya fue recargada
    if (!sessionStorage.getItem('reloaded')) {
      // Si no ha sido recargada, la recargamos
      sessionStorage.setItem('reloaded', 'true');
      location.reload();
    }
  },

  methods: {
  }
}
</script>
