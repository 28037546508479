<template>
  <div>
    <div class="px-0">
      <h5 class="text-black text-center fw-600 mt-0 mb-2">{{title_chart}}</h5>
      <highcharts class="d-block mx-auto" :options="chartOptions" :updateArgs="updateArgs" v-if="chart_enabled"></highcharts>
    </div>
  </div>
</template>

<script>
import PieChartController from "../controllers/PieChartController";
export default {
  props: ["title_chart", "data_list", "endpoint"],
  data: () => {
    return {
      chart_enabled: false,
      chartOptions: {},
      updateArgs: [true, true, { duration: 1000 }],

      pie_chart_controller: null,
    };
  },
  mounted() {
    this.pie_chart_controller = new PieChartController(this);
  },
  methods: {
    getView() {
      this.pie_chart_controller.getView();
    },
  },
};
</script>