import UI from  '../../../core/helpers/UI';
// import File from "../../../core/helpers/File";
import PositionsUsersService from '../services/PositionsUsersService';

class PositionsUsersController {
  context;
  positions_users_service;
  valid;

  constructor(context) {
    this.context = context;
    this.positions_users_service = new PositionsUsersService();

    this.context.headers = [
      { text: 'Nombre', value: 'name' },
      { text: 'Descripción', value: 'description' },
      { text: 'Fecha creación', value: 'date' },
      { text: '', value: 'actions' },
    ];

    this._listPositionsUsers();
  }

  _listPositionsUsers() {
    this.context.lists = [];
    UI.loading('show');
    this.positions_users_service.listPositionsUsers()
    .then((r) => {
      console.log(r);  // Añade este log para revisar la respuesta
      if(r.success == true) {
        let list = [];
        if(r.data.items && r.data.items.length > 0) {
          r.data.items.forEach((v) => {
            list.push({
              cuenta_id: v.cuenta_id,
              date: v.date,
              description: v.description != null && v.description != 'null' ? v.description : '',
              name: v.name,
              position_ente_id: v.position_ente_id,
              position_id: v.position_id,
              state: v.state
            });
          });
          this.context.lists = list;
        }
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }
  

  deletePositionUser(position_id) {
    UI.loading('show');
    this.positions_users_service.deletePositionUser(position_id)
    .then((r) => {
      if(r.success == true) {
        UI.mensajeOk('', r.msg);
        this._listPositionsUsers();
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  openDialog() {
    this.context.$set(this.context.dialog_manage, 0, true);
  }

  closeDialog() {
    this.context.$set(this.context.dialog_manage, 0, false);
    this.context.$refs.form_manage.reset();
    this.context.position_id = null;
    this.context.is_edit = false;
  }

  editPositionUser(info) {
    this.openDialog();
    this.context.is_edit = true;
    this.context.position_id = info.position_id;
    this.context.name = info.name;
    this.context.description = info.description;
  }

  managePositionUser() {
    if(this.context.is_edit) {
      this._updatePositionUser();
    } else {
      this._createPositionUser();
    }
  }

  _createPositionUser() {
    if(this.context.$refs.form_manage.validate()) {
      UI.loading('show');
      this.positions_users_service.createPositionUser(this.context.name, this.context.description)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.closeDialog();
          this._listPositionsUsers();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  _updatePositionUser() {
    if(this.context.$refs.form_manage.validate()) {
      UI.loading('show');
      this.positions_users_service.updatePositionUser(this.context.position_id, this.context.name, this.context.description)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.closeDialog();
          this._listPositionsUsers();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }
}

export default PositionsUsersController;