// La importación de _ de core-js no se está utilizando, así que la eliminamos
import { _ } from "core-js";

export default class Session {
  static set(key, value) {
    localStorage.setItem(key, String(value));
  }

  static get(key) {
    return localStorage.getItem(key);
  }

  static toggleValue(key) {
    const currentKey = Session.get(key);

    if (currentKey === undefined) {
      return false;
    } else if (currentKey === 'TRUE') {
      Session.set(key, 'FALSE');
      return true;
    } else {
      Session.set(key, 'TRUE');
      return false;
    }
  }

  static paramDeclared(key) {
    const currentKey = Session.get(key);

    if (currentKey === undefined) {
      Session.set(key, 'TRUE');
      return false;
    } else if (currentKey === 'TRUE') {
      return true;
    }
    // Añadimos un return por defecto para manejar todos los casos
    return false;
  }

  static destroyAll() {
    const menuStatus = Session.get('menu_opened');
    localStorage.clear();
    Session.set('menu_opened', menuStatus);
  }
}