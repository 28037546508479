import UI from '../../../helpers/UI';
import JobService from "../services/JobService";

class JobController {

  context;
  job_service;

  constructor(context) {
    this.context = context;
    this.job_service = new JobService();
  }

  scheduleJobs() {
    UI.loading('show');
    this.job_service.scheduleJobs('EACH_MINUTE')
    .then(() => { // Eliminar 'r' si no se usa
        UI.loading('hide');
    }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
    });
  }

  processJobs() {
    UI.loading('show');
    this.job_service.processJobs()
    .then(() => { // Eliminar 'r' si no se usa
        UI.loading('hide');
    }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
    });
  }

  processJobsV2() {
    UI.loading('show');
    this.job_service.processJobsV2()
    .then(() => { // Eliminar 'r' si no se usa
        UI.loading('hide');
    }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
    });
  }
}

export default JobController;
