import Rest from '../../../core/libs/Rest';
// Se ha eliminado la siguiente línea:
// import { UPDATE_MOCKUP } from '../mockups/Update';

class UpdateRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoCompany() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/company/show-company');
    return this.rest.get();
  }

  updateCompany(photo, list_address, scheduled, website, facebook, instagram, twitter, linkedin, skype, email, url_company, url1, url2, url3, descripcion1, descripcion2, descripcion3) {
    this.rest.setParams({
      image_logo  : photo, 
      address     : list_address, 
      schedule    : scheduled, 
      website     : website, 
      facebook    : facebook, 
      instagram   : instagram, 
      twitter     : twitter, 
      linkedin    : linkedin, 
      skype       : skype, 
      email       : email, 
      url_company : url_company,
      url1        : url1, 
      url2        : url2, 
      url3        : url3, 
      descripcion1: descripcion1,
      descripcion2: descripcion2, 
      descripcion3: descripcion3});
    this.rest.setEndPoint('/api/card/company/update-company');
    return this.rest.put();
  }
}

export default UpdateRepository;