import FilterService from "../services/FilterService";
import UI from "../../../../helpers/UI";
import ErrorManager from "../../../../libs/ServiceResponse/ErrorManager";

class FilterController {
  context;
  filter_service;

  constructor(context) {
    this.context = context;
    this.filter_service = new FilterService();
    setTimeout(() => {
      this._readDataFromAPI();
    }, 1000);
  }

  _readDataFromAPI() {
    UI.loading('show');
    this.context.loading = true;
    this.filter_service.readDataFromAPI(this.context.filter_info.endpoint)
    .then((r) => {
      if(r.success == true) {
        this.context.loading = false;
        this.context.items = r.data;
        UI.loading('hide');
      }
    })
    .catch((e) => new ErrorManager(e, () => {
      this.context.loading = false;
      UI.loading('hide');
    }));
  }
}

export default FilterController;