import PieChartService from "../services/PieChartService";
import ErrorManager from "../../../libs/ServiceResponse/ErrorManager";
import UI from  '../../../helpers/UI';

class PieChartController {
  context;
  pie_chart_service;

  constructor(context) {
    this.context = context;
    this.pie_chart_service = new PieChartService();
    this.initializeChart();
  }

  initializeChart(){
    console.log('data_list',this.context.data_list);
    //console.log('endpoint',this.context.endpoint);
    if(this.context.data_list.length > 0) {
      this.showChart(this.context.data_list);
    } else if(this.context.endpoint) {
      this._getDataChart();
    }
  }

  _getDataChart() {
    UI.loading('show');
    this.pie_chart_service.getDataChart(
      this.context.endpoint
    )
    .then((r) => {
      if(r.success == true) {
        this.showChart(r.data);
        UI.loading('hide');
      }
    })
    .catch((e) => new ErrorManager(e, () => {
      UI.loading('hide');
    }));
  }

  showChart(data) {
    this.context.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie" //column
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<strong>{point.y}%</strong><br/>',
      },
      title: {
        text: ""
      },
      series: [{
        data: data
      }]
    };
    this.context.chart_enabled = true;
  }
}

export default PieChartController;