import PaginatorService from "../services/PaginatorService";
import ErrorManager from "../../../../libs/ServiceResponse/ErrorManager";
import UI from  '../../../../helpers/UI';
import Helpers from "../../../../helpers/Helpers";

class PaginatorSimpleController {
  context;
  paginator_service;

  constructor(context) {
    this.context = context;
    this.paginator_service = new PaginatorService();
    console.log('config_buttons', this.context.config_buttons);
    this._readDataFromAPISimple();
    this.configureButtons();
  }

  _readDataFromAPISimple() {
    UI.loading('show');
    this.context.loading = true;
    this.paginator_service.readDataFromAPISimple(this.context.endpoint)
    .then((r) => {
      if(r.success == true) {
        this.context.loading = false;
        this.context.passengers = r.data;
        UI.loading('hide');
      }
    })
    .catch((e) => new ErrorManager(e, () => {
      this.context.loading = false;
      UI.loading('hide');
    }));
  }

  configureButtons(){
      if(this.context.config_buttons.length > 0 && !this.validateColumnActions()){
          setTimeout(()=>{
            this.context.headers.push({ text: 'Acciones', value: 'actions', sortable: false });
          }, 1000); 
      }
  }

  validateColumnActions(){
    for (let i = 0; i < this.context.headers.length; i++) {
      const e = this.context.headers[i];
      if(e.value == 'actions'){
        return true;
      }
    }
    return false;
  }

  configureButtonUrl(item_entity, item_button){
    let url = item_button.url;
    for (let param of item_button.params_url) {
        url = url.replace("["+param+"]", item_entity[param]);
    }
    return Helpers.urlapplink('crm', url);
  }

  deleteFromAPI(endpoint, id, type_petition) {
    UI.loading('show');
    this.paginator_service.deleteFromAPI(endpoint, id, type_petition)
    .then((r) => {
      if(r.success == true) {
        this._readDataFromAPISimple();
        UI.mensajeOk('', r.msg);        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

}

export default PaginatorSimpleController;