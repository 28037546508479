import Principal from '../../core/layouts/Principal.vue';
import Manage from './views/Manage.vue';
// import TypesUsers from './views/TypesUsers.vue';
import PositionsUsers from './views/PositionsUsers.vue';
import List from './views/List.vue';
import Detail from './views/Detail.vue';

window.routes.push({
  path: '/users',
  name: 'users',
  component: Principal,
  children: [
    {
      path: 'list',
      name: 'list',
      component: List,
    },
    {
      path: 'manage/:id?',
      name: 'manageUsers',
      component: Manage,
    },
    {
      path: 'detail/:id?',
      name: 'detail',
      component: Detail,
    },
    // {
    //   path: 'types-users',
    //   name: 'types-users',
    //   component: TypesUsers,
    // },
    {
      path: 'positions-users',
      name: 'positions-users',
      component: PositionsUsers,
    }
  ],
});