// import UI from  '../../../core/helpers/UI';
import LectorService from '../services/LectorService';

class LectorController {
  context;
  lector_service;

  constructor(context) {
    this.context = context;
    this.lector_service = new LectorService();
  }

  onDecode(id) {
    window.location = '#/web/profile/'+id;
  }
}

export default LectorController;