<template>
    <div>
        <v-select 
            filled
            v-model="p_model"
            :items="items" 
            :label="filter_info.label" 
            :item-value="filter_info.config_extra.item_value" 
            :item-text="filter_info.config_extra.item_text"
            clearable
        ></v-select>
    </div>
</template>

<script>
import FilterController from '../controllers/FilterController';
export default {
    props: ['filter_info'],
    data: () => {
        return {
            p_model: null,
            items: [],
            filter_select_controller: null,
        };
    },
    mounted(){
        //test
        this.filter_select_controller = new FilterController(this);
    },
    methods: {
        getValue(){
            return this.p_model;
        }
    }
}
</script>