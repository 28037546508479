import Helpers from "../helpers/Helpers";

export default class Enviroment{

    static getUrlEndpoint(){
        if(!Helpers.isEmpty(window.ENVIROMENT.URL_ENDPOINT)){
            return window.ENVIROMENT.URL_ENDPOINT;
        }else{
            return process.env.VUE_APP_URL_ENDPOINT;
        }
    }

    static getUrlZfiles(){
        if(!Helpers.isEmpty(window.ENVIROMENT.URL_ZFILES)){
            return window.ENVIROMENT.URL_ZFILES;
        }else if(!Helpers.isEmpty(process.env.VUE_APP_URL_ZFILES)){
            return process.env.VUE_APP_URL_ZFILES;
        }else{
            return Enviroment.getUrlEndpoint() +'zfiles/';
        }
    }

    static getUrlDashboard(){
        if(!Helpers.isEmpty(window.ENVIROMENT.URL_DASHBOARD)){
            return window.ENVIROMENT.URL_DASHBOARD;
        }else{
            return Helpers.urlmodulo('crcore','dashboard/home');
        }
    }

    static getTokenInitial(){
        try{
            return process.env.VUE_APP_TOKEN_INITIAL;
        }catch(e){
            return '';
        }
    }

    static getTokenDefault(){
        try{
            return process.env.VUE_APP_TOKEN_SYS;
        }catch(e){
            return false;
        }
    }
}
