import PaginatorService from "../services/PaginatorService";
import UI from "../../../../helpers/UI";
import ErrorManager from "../../../../libs/ServiceResponse/ErrorManager";
import Helpers from "../../../../helpers/Helpers";

class PaginatorViewController {
  context;
  paginator_service;

  constructor(context) {
    this.context = context;
    this.paginator_service = new PaginatorService();
    this.getTableData();
    this.setMessageHintSearch();
    setTimeout(() => {
      this.configureButtons();
    }, 2000);
  }

  getTableData(){
    if(this.context.mockup_data && this.context.mockup_data.success){
      console.log('tabla--', 'mockup');
      this.context.loading = false;
      this.context.canGetData = true;
      this.context.passengers = this.context.mockup_data.data.items;
      this.context.totalPassengers = this.context.mockup_data.data.total;
      this.context.numberOfPages = this.context.mockup_data.data.total_pages;
    }else{
      console.log('tabla--', 'api');
      this.readDataFromAPIExtended();
    }
  }

  readDataFromAPIExtended() {
    if(this.context.canGetData){
      UI.loading('show');
      this.context.loading = true;
      this.context.canGetData = false;
      const { page, itemsPerPage, sortBy, sortDesc } = this.context.options;
      let sortByValue = sortBy[0] ? sortBy[0] : '';
      let page_number = page == 0 ? 1 : page;
      let query_params = "?page="+ page_number +"&limit="+ itemsPerPage;
      query_params += "&columsort="+ sortByValue +"&sortdesc="+ sortDesc +"&search="+ this.context.search +"&view_ini=0";
      if(this.context.filters_str != ''){
        query_params += this.context.filters_str;
      }
      this.paginator_service.readDataFromAPIExtended(this.context.endpoint, query_params)
      .then((r) => {
        if(r.success == true) {
          this.context.loading = false;
          this.context.canGetData = true;
          this.context.passengers = r.data.items;
          this.context.totalPassengers = r.data.total;
          this.context.numberOfPages = r.data.total_pages;
          UI.loading('hide');
        }
      })
      .catch((e) => new ErrorManager(e, () => {
        this.context.loading = false;
        this.context.canGetData = true;
        UI.loading('hide');
      }));
    }
  }

  configureButtons(){
    console.log('config_buttons_444',this.context.config_buttons);
    if(this.context.config_buttons.length > 0 && !this.validateColumnActions()){
      this.context.headers.push({ text: 'Acciones', value: 'actions', sortable: false });
    }
  }

  validateColumnActions(){
    for (let i = 0; i < this.context.headers.length; i++) {
      const e = this.context.headers[i];
      if(e.value == 'actions'){
        return true;
      }
    }
    return false;
  }

  configureButtonUrl(item_entity, item_button){
    let url = item_button.url;
    for (let param of item_button.params_url) {
        url = url.replace("["+param+"]", item_entity[param]);
    }
    return Helpers.urlapplink('crm', url);
  }

  deleteFromAPI(endpoint, id) {
    UI.mensajeConfirm("¿Está seguro?","¡No podrá recuperar este registro más adelante!")
    .then((res)=>{
      if(res.isConfirmed){
        UI.loading('show');
        this.paginator_service.deleteFromAPI(endpoint, id)
        .then((r) => {
          if(r.success == true) {
            UI.mensajeOk('', r.msg);        
            UI.loading('hide');
            this.getTableData();
          }
        }).catch((e) => {
          UI.loading('hide');
          UI.mensajeBad('', e.msg);
        });
      }
    });    
  }

  filterData(){
    let validate = this.context.$refs['filter_table_view'].validate();    
    console.log('validate', validate);
    if(!validate.ban){
      UI.mensajeBad('', validate.msg);
      return false;
    }
    
    this.context.filters_str = this.context.$refs['filter_table_view'].getValue();
    console.log('filters_str', this.context.filters_str);
    this.getTableData();
  }

  setMessageHintSearch(){
    let msg = 'Solo filtra por las siguientes columnas: ';
    let is_first = false;
    for (let i = 0; i < this.context.headers.length; i++) {
      const e = this.context.headers[i];
      if(!e.is_property){
        if(!is_first){
          msg += e.text;
          is_first = true;
        }else{
          msg += ', ' + e.text;
        }
      }
    }
    this.context.message_hint_search = msg;
  }
}

export default PaginatorViewController;