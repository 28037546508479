<template>
  <section class="main">
    <div class="container-fluid main-box-content contentModulo py-2 px-3 px-lg-2">
      <div class="custom-breadcrumb mb-3">
        <ul>
          <li><a href="">Ejemplos</a></li>
          <li>Paginador</li>
        </ul>
      </div>
      <div class="px-md-3">
          <v-card>
            <v-card-title>Paginador simple</v-card-title>
            <v-card-text>
              <table-paginator-simple
                :headers="headers_simple"
                :endpoint="endpoint_simple"
                :config_buttons="config_buttons_simple"
              ></table-paginator-simple>
            </v-card-text>
          </v-card>

          <br>

          <v-card>
            <v-card-title>Paginador extendido</v-card-title>
            <v-card-text>
              <table-paginator-extended
                :headers="headers_extended"
                :endpoint="endpoint_extended"
                :config_buttons="config_buttons_extended"
                :filters="filters_extended"
                ref="table-paginator-extended-ref"
              ></table-paginator-extended>
            </v-card-text>
          </v-card>

          <br>

          <v-card>
            <v-card-title>Paginador con mockup</v-card-title>
            <v-card-text>
              <table-paginator-extended
                :headers="headers_extended"
                :endpoint="endpoint_extended"
                :config_buttons="config_buttons_extended"
                :filters="filters_extended"
                ref="table-paginator-mockup"
                :mockup_data="PAGINATE_MOCKUP.LIST_CONTACTS"
              ></table-paginator-extended>
            </v-card-text>
          </v-card>
      </div>
    </div>
  </section>
</template>

<script>
//import LoadFileController from '../controllers/LoadFileController';
import { PAGINATE_MOCKUP } from "../mockups/Paginate";

export default {
  name: "ExamplePaginator", // Nuevo nombre del componente
  data() {
    return {
      PAGINATE_MOCKUP,
      headers_simple: [
        { text: 'Nombres', value: 'names' },
        { text: 'Apellidos', value: 'last_names' },
        { text: 'Correo', value: 'email' },
        { text: 'Teléfono', value: 'phone' }
      ],
      headers_extended: [
        { text: 'Nombres', value: 'names' },
        { text: 'Apellidos', value: 'last_names' },
        { text: 'Correo', value: 'email' },
        { text: 'Teléfono', value: 'phone' },
        { text: 'Origen', value: 'origin_name' }
      ],
      config_buttons_simple: [
        {
          label: 'Ver contacto', 
          icon: 'mdi-eye-outline', 
          color: 'primary', 
          url: 'contacts/detail-contact/[contact_id]', 
          params_url: ['contact_id'],
          webservice: {}
        },
        { 
          label: 'Editar contacto', 
          icon: 'mdi-pencil-outline', 
          color: 'info', 
          url: 'contacts/create-contact/[contact_id]', 
          params_url: ['contact_id'], 
          webservice: {}
        },
        { 
          label: 'Eliminar contacto', 
          icon: 'mdi-trash-can-outline', 
          color: 'danger', 
          url: '', 
          params_url: [], 
          webservice: {
            endpoint: 'api/crmv2/products',
            param: 'contact_id',
            type_petition: 'PUT' // DELETE | PUT
          }
        },
      ],
      config_buttons_extended: [
        { 
          label: 'Ver contacto', 
          icon: 'mdi-eye-outline', 
          color: 'primary', 
          url: 'contacts/detail-contact/[contact_id]', 
          params_url: ['contact_id'],
          webservice: {}
        },
        { 
          label: 'Editar contacto', 
          icon: 'mdi-pencil-outline', 
          color: 'info', 
          url: 'contacts/create-contact/[contact_id]', 
          params_url: ['contact_id'], 
          webservice: {}
        },
        { 
          label: 'Eliminar contacto', 
          icon: 'mdi-trash-can-outline', 
          color: 'danger', 
          url: '', 
          params_url: [],
          webservice: {
            endpoint: 'api/crmv2/products',
            param: 'contact_id',
            type_petition: 'DELETE' // DELETE | PUT
          }
        },
      ],
      endpoint_simple: 'api/core/examples-no-pagination',
      endpoint_extended: 'api/core/examples-pagination-with-joins',
      filters_extended: [
        {
          type: 'TEXT',
          label: 'Nombres',
          ref: 'names',
          model: null,
          endpoint: ''
        },
        {
          type: 'DATE',
          type_date_option: {
            modal_init: false,
          },
          label: 'Fecha de inicio',
          ref: 'start_date',
          model: null,
          endpoint: ''
        },
        {
          type: 'DATE',
          type_date_option: {
            modal_init: false,
          },
          label: 'Fecha fin',
          ref: 'end_date',
          model: null,
          endpoint: ''
        },
        {
          type: 'SELECT',
          type_select_option: {
            item_value: 'product_id',
            item_text: 'name',
          },
          label: 'Producto',
          ref: 'serialized_productsarray',
          model: null,
          endpoint: 'api/crmv2/products-all'
        }
      ],
      load_controller: null,
      mockup_data: []
    }
  },
  mounted() {
    console.log('PAGINATE_MOCKUP', PAGINATE_MOCKUP.LIST_CONTACTS);
    //this.load_controller = new LoadFileController(this);
  },
  methods: {
    
  }
}
</script>
