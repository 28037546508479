import BriefcaseRepository from "../repositories/BriefcaseRepository";

class BriefcaseService {
  briefcase_repository;

  constructor() {
    this.briefcase_repository = new BriefcaseRepository();
  }

  async listBriefcases() {
    let r = await this.briefcase_repository.listBriefcases();
    return r;
  }
}

export default BriefcaseService;