<template>
  <div>
    <section class="main-client">
      <div class="row justify-content-center pt-4">
        <div class="col-md-10 col-lg-9">
          <div class="row align-items-stretch">
            <!-- Tarjetas de servicios -->
            <div v-for="(service, index) in services" :key="index" class="col-sm-6 mb-4 pb-1">
              <v-card class="h-100" min-height="130" :href="service.href">
                <v-card-text>
                  <div class="row py-3 mx-n2 align-items-center">
                    <div class="col-sm-4 px-2 text-center">
                      <v-icon :color="service.color" class="fz-66">{{ service.icon }}</v-icon>
                    </div>
                    <div class="col-sm-8 px-2 mt-2 mt-sm-0">
                      <h4 class="my-0 fw-600">{{ service.title }}</h4>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>

          <v-card class="mt-4">
            <v-card-title class="fw-600">Últimas solicitudes</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="5"
              >
                <template #[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="viewDetail(item)">
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalle</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="deleteItem(item)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      value: 'home',
      headers: [
        { text: 'Nombre', value: 'lorem' },
        { text: 'Fecha', value: 'ipsum' },
        { text: '', value: 'actions' },
      ],
      desserts: [
        { item_id: 1, lorem: 'Certificado', ipsum: '14-05-2022' },
        { item_id: 2, lorem: 'Certificado', ipsum: '14-05-2022' },
        { item_id: 3, lorem: 'Certificado', ipsum: '14-05-2022' },
        { item_id: 4, lorem: 'Certificado', ipsum: '14-05-2022' },
        { item_id: 5, lorem: 'Certificado', ipsum: '14-05-2022' },
        { item_id: 6, lorem: 'Certificado', ipsum: '14-05-2022' },
      ],
      services: [
        { title: 'CRM', icon: 'mdi-bulletin-board', color: 'secondary', href: '' },
        { title: 'Learning', icon: 'mdi-school-outline', color: 'success', href: '' },
        { title: 'Intranet', icon: 'mdi-apps', color: 'warning', href: '' },
        { title: 'Encuestas', icon: 'mdi-chart-box-outline', color: 'error', href: '' },
      ],
    };
  },
  methods: {
    viewDetail(item) {
      console.log('Ver detalle de:', item);
      // Implementar lógica para ver detalles
    },
    deleteItem(item) {
      console.log('Eliminar:', item);
      // Implementar lógica para eliminar
    },
  },
};
</script>