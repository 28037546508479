export const PAGINATE_MOCKUP = {
  LIST_CONTACTS: {
    success: true,
    data: {
      total: 34,
      total_pages: 1,
      currentPage: 1,
      perPage: 30,
      prevPage: -1,
      nextPage: 1,
      listRagePages: [[1, false]],
      items: [
        {
          follow_up_id: 34,
          follow_up_ente_id: 34,
          cuenta_id: 1,
          contact_id: 35,
          quotation_id: null,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: null,
          description: "test",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-04-21 13:03:01",
          name_follow_up: "Contacto: Carlos18 Arzuza18",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: {
            contact_id: 35,
            names: "Carlos18",
            last_names: "Arzuza18",
            document_type: "PA",
            files: null,
            ente: 2,
            email: "caarzuza18@gmail.com",
            phone: "458796325",
            property_value: JSON.stringify([
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-2",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "ABC679",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE_RANGE",
                  value_array: [],
                },
                value: "2022-04-15",
              },
            ]),
            files_array: [],
            ente_name: "Lead",
            full_name: "Carlos18 Arzuza18",
            document_type_name: "Pasaporte",
            property_value_array: [
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-2",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "ABC679",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE_RANGE",
                  value_array: [],
                },
                value: "2022-04-15",
              },
            ],
            property_value_table: {
              ciclo_lectivo: "2022-2",
              placa_vehiculo: "ABC679",
              fecha_revision: "2022-04-15",
            },
          },
          quotation: null,
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 33,
          follow_up_ente_id: 33,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 16,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: "18",
          description: "test",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-04-21 08:29:19",
          name_follow_up: "Cotización: negocio 13",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 16,
            name: "negocio 13",
            files: "[]",
            files_array: [],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 32,
          follow_up_ente_id: 32,
          cuenta_id: 1,
          contact_id: 26,
          quotation_id: null,
          usuario_id: 1,
          type_follow_up_id: 5,
          state_id: null,
          description: "prueba de seguimiento",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-02-28 22:03:02",
          name_follow_up: "Contacto: Carlos4 Arzuza4",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: {
            contact_id: 26,
            names: "Carlos4",
            last_names: "Arzuza4",
            document_type: "CC",
            files: JSON.stringify([
              "a4670c7a42808c9-tk-_sl_Fcrm2_sl_F2022_sl_F03_sl_F04_sl_F1646425524_om_PRUEBA.pdf",
            ]),
            ente: 3,
            email: "caarzuza10@gmail.com",
            phone: "458796325",
            property_value: JSON.stringify([
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-1",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "ABC681",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE",
                  value_array: [],
                },
                value: "",
              },
            ]),
            files_array: [
              {
                index: 0,
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/a4670c7a42808c9-tk-_sl_Fcrm2_sl_F2022_sl_F03_sl_F04_sl_F1646425524_om_PRUEBA.pdf",
              },
            ],
            ente_name: "Cliente",
            full_name: "Carlos4 Arzuza4",
            document_type_name: "Cédula de Ciudadanía",
            property_value_array: [
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-1",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "ABC681",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE",
                  value_array: [],
                },
                value: "",
              },
            ],
            property_value_table: {
              ciclo_lectivo: "2022-1",
              placa_vehiculo: "ABC681",
              fecha_revision: "",
            },
          },
          quotation: null,
          type_follow_up: {
            type_follow_up_id: 5,
            name: "Tipo 2",
          },
        },
        {
          follow_up_id: 31,
          follow_up_ente_id: 31,
          cuenta_id: 1,
          contact_id: 21,
          quotation_id: null,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: null,
          description: "prueba de seguimiento",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-02-18 22:19:37",
          name_follow_up: "Contacto: Carlos4 Arzuza4",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: {
            contact_id: 21,
            names: "Carlos4",
            last_names: "Arzuza4",
            document_type: "CC",
            files: null,
            ente: 1,
            email: "caarzuza4@gmail.com",
            phone: "458796325",
            property_value: JSON.stringify([
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-1",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "ABC681",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE",
                  value_array: [],
                },
                value: "",
              },
            ]),
            files_array: [],
            ente_name: "Contacto",
            full_name: "Carlos4 Arzuza4",
            document_type_name: "Cédula de Ciudadanía",
            property_value_array: [
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-1",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "ABC681",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE",
                  value_array: [],
                },
                value: "",
              },
            ],
            property_value_table: {
              ciclo_lectivo: "2022-1",
              placa_vehiculo: "ABC681",
              fecha_revision: "",
            },
          },
          quotation: null,
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 30,
          follow_up_ente_id: 30,
          cuenta_id: 1,
          contact_id: 6,
          quotation_id: null,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: null,
          description: "prueba de seguimiento",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-02-01 20:00:30",
          name_follow_up: "Contacto: Juan Areas",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: {
            contact_id: 6,
            names: "Juan",
            last_names: "Areas",
            document_type: "CC",
            files: null,
            ente: 1,
            email: "jareas@gmail.com",
            phone: "454556",
            property_value: JSON.stringify([
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-1",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "IUY888",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE",
                  value_array: [],
                },
                value: "",
              },
            ]),
            files_array: [],
            ente_name: "Contacto",
            full_name: "Juan Areas",
            document_type_name: "Cédula de Ciudadanía",
            property_value_array: [
              {
                propertie_info: {
                  property_id: 1,
                  label: "Ciclo lectivo",
                  key: "ciclo_lectivo",
                  type: "SELECT",
                  value_array: [
                    { text: "2021-2", value: "2021-2" },
                    { text: "2022-1", value: "2022-1" },
                    { text: "2022-2", value: "2022-2" },
                  ],
                },
                value: "2022-1",
              },
              {
                propertie_info: {
                  property_id: 2,
                  label: "Placa vehiculo",
                  key: "placa_vehiculo",
                  type: "TEXT",
                  value_array: [],
                },
                value: "IUY888",
              },
              {
                propertie_info: {
                  property_id: 3,
                  label: "Fecha revision",
                  key: "fecha_revision",
                  type: "DATE",
                  value_array: [],
                },
                value: "",
              },
            ],
            property_value_table: {
              ciclo_lectivo: "2022-1",
              placa_vehiculo: "IUY888",
              fecha_revision: "",
            },
          },
          quotation: null,
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 29,
          follow_up_ente_id: 29,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 6,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: "CEP",
          description: "prueba de seguimiento con causa",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 19:52:17",
          name_follow_up: "Cotización: Negocio 4",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 6,
            name: "Negocio 4",
            files: JSON.stringify(["PRUEBA.pptx", "PRUEBA.pdf"]),
            files_array: [
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/PRUEBA.pptx",
                value: "PRUEBA.pptx",
              },
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/PRUEBA.pdf",
                value: "PRUEBA.pdf",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 28,
          follow_up_ente_id: 28,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 6,
          usuario_id: 1,
          type_follow_up_id: 2,
          state_id: "CEP",
          description: "prueba de seguimiento con causa",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 19:50:09",
          name_follow_up: "Cotización: Negocio 4",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 6,
            name: "Negocio 4",
            files: JSON.stringify(["PRUEBA.pptx", "PRUEBA.pdf"]),
            files_array: [
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/PRUEBA.pptx",
                value: "PRUEBA.pptx",
              },
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/PRUEBA.pdf",
                value: "PRUEBA.pdf",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 2,
            name: "tipo seguimiento editado",
          },
        },
        {
          follow_up_id: 27,
          follow_up_ente_id: 27,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 7,
          usuario_id: 1,
          type_follow_up_id: 5,
          state_id: "18",
          description: "prueba de seguimiento (detalle de contacto)",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 19:49:12",
          name_follow_up: "Cotización: negocio 6-",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 7,
            name: "negocio 6-",
            files: JSON.stringify([
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
            ]),
            files_array: [
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              },
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 5,
            name: "Tipo 2",
          },
        },
        {
          follow_up_id: 26,
          follow_up_ente_id: 26,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 7,
          usuario_id: 1,
          type_follow_up_id: 5,
          state_id: "CEP",
          description: "test",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 19:44:49",
          name_follow_up: "Cotización: negocio 6-",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 7,
            name: "negocio 6-",
            files: JSON.stringify([
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
            ]),
            files_array: [
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              },
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 5,
            name: "Tipo 2",
          },
        },
        {
          follow_up_id: 25,
          follow_up_ente_id: 25,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 7,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: "CEP",
          description: "test",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 19:40:55",
          name_follow_up: "Cotización: negocio 6-",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 7,
            name: "negocio 6-",
            files: JSON.stringify([
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
            ]),
            files_array: [
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              },
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 24,
          follow_up_ente_id: 24,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 7,
          usuario_id: 1,
          type_follow_up_id: 4,
          state_id: "18",
          description: "prueba",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 19:34:18",
          name_follow_up: "Cotización: negocio 6-",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 7,
            name: "negocio 6-",
            files: JSON.stringify([
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
            ]),
            files_array: [
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              },
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 4,
            name: "tipo seguimiento 1",
          },
        },
        {
          follow_up_id: 23,
          follow_up_ente_id: 23,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 7,
          usuario_id: 1,
          type_follow_up_id: 5,
          state_id: "18",
          description: "test",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 16:35:34",
          name_follow_up: "Cotización: negocio 6-",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 7,
            name: "negocio 6-",
            files: JSON.stringify([
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
            ]),
            files_array: [
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              },
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 5,
            name: "Tipo 2",
          },
        },
        {
          follow_up_id: 22,
          follow_up_ente_id: 22,
          cuenta_id: 1,
          contact_id: null,
          quotation_id: 7,
          usuario_id: 1,
          type_follow_up_id: 5,
          state_id: "18",
          description: "test",
          files: "[]",
          sent_mail: 2,
          state: 1,
          date: "2022-01-24 16:35:18",
          name_follow_up: "Cotización: negocio 6-",
          files_array: [],
          adviser: {
            usuario_id: 1,
            tipo_id: 4,
            pri_nombre: "Andres",
            pri_apellido: "Castillo",
            seg_nombre: null,
            seg_apellido: null,
            email: "desarrollo@desarrollo.com",
            foto: "%252Fcollaborators%252F2021%252F10%252F15%252F1634327284_om_perfil%2Bhombre.jpg",
            full_name: "Andres Castillo",
          },
          contact: null,
          quotation: {
            quotation_id: 7,
            name: "negocio 6-",
            files: JSON.stringify([
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
            ]),
            files_array: [
              {
                name: "PRUEBA.pdf",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499097_om_PRUEBA.pdf",
              },
              {
                name: "PRUEBA.pptx",
                link: "http://localhost/office-manager-crm/api/zfiles/8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
                value: "8ae0206c489ce5-tk-_sl_Fcrm2_sl_F2021_sl_F12_sl_F14_sl_F1639499111_om_PRUEBA.pptx",
              },
            ],
            property_value_array: [],
            property_value_table: {},
          },
          type_follow_up: {
            type_follow_up_id: 5,
            name: "Tipo 2",
          },
        },
      ],
    },
  },
};

export function listFollowUpsPaginate(query = {}) {
  const url = `/api/seguimiento/paginado`;
  const params = new URLSearchParams(query).toString();
  return fetch(url + "?" + params)
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        return data.data;
      }
      throw new Error("Error en la respuesta del servidor");
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
    });
}
