import FilterSelectRepository from "../repositories/FilterSelectRepository";

class FilterSelectService {
  filter_select_repository;
  
  constructor() {
    this.filter_select_repository = new FilterSelectRepository();
  }

  async readDataFromAPI(endpoint) {
    let response_service = await this.filter_select_repository.readDataFromAPI(endpoint);
    return response_service;
  }
}

export default FilterSelectService;