<template>
    <div>
      <div class="row text-center">
        <div v-for="(item, index) in filters" :key="index" class="col-sm-3">
          <v-text-field
            v-if="item.type == 'TEXT'"
            v-model="item.model"
            :label="item.label"
            :ref="'ref_'+ item.ref"
            filled
            clearable
          ></v-text-field>
  
          <v-dialog v-if="item.type == 'DATE_RANGE'" :ref="'dialog_'+ item.ref +'_'+ item.type_date_range" v-model="item.config_extra.modal_init" :return-value.sync="item.model" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field 
                v-model.lazy="item.model" 
                :label="item.label" 
                readonly 
                filled 
                clearable 
                v-bind="attrs" 
                v-on="on"                            
                :ref="'ref_'+ item.ref +'_'+ item.type_date_range"
              ></v-text-field>
            </template>
            <v-date-picker v-model="item.model" scrollable locale="es" color="primary">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="item.config_extra.modal_init = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs['dialog_'+ item.ref +'_'+ item.type_date_range][0].save(item.model)">
                Seleccionar
              </v-btn>
            </v-date-picker>
          </v-dialog>
  
          <filter-select-view
            v-if="item.type == 'SELECT'"
            :filter_info="item"
            :ref="'ref_'+ item.ref"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Helpers from '../../../../helpers/Helpers';
  
  export default {
    name: 'DynamicFilters', // Actualiza aquí el nombre del componente
    props: ['filters'],
    data: () => {
      return {
        Helpers,
        filter_controller: null,
        filter_select_controller: null
      };
    },
    mounted(){
      //this.filter_controller = new FilterSelectController(this);
    },
    methods: {
      validate(){
        let res = {ban: true, msg: ''};
        let info = {};
        this.filters.forEach((v) => {
          let ref = 'ref_'+ v.ref;
          if(v.type == 'DATE_RANGE'){
            ref = 'ref_'+ v.ref +'_'+ v.type_date_range;
          }
          let value_ref = '';
          if(!this.$refs[ref][0].$refs.input){
            value_ref = this.$refs[ref][0].getValue();
          }else{
            value_ref = this.$refs[ref][0].$refs.input._value;
          }                
          if(v.type == 'DATE_RANGE'){
            if(v.type_date_range == 'start'){
              res.ban = true;
              res.msg = '';
              info.label = v.label;
              info.type_date_range = v.type_date_range;
              info.ref = ref;
              info.value = Helpers.validateValue(value_ref);
            }else if(v.type_date_range == 'end'){
              if(!Helpers.validateValue(value_ref) && info.value){
                res.ban = false;
                res.msg = 'Debe completar el campo '+ v.label;
              }else if(Helpers.validateValue(value_ref) && !info.value){
                res.ban = false;
                res.msg = 'Debe completar el campo '+ info.label;
              }else if(Helpers.validateValue(value_ref) < info.value){
                res.ban = false;
                res.msg = 'El valor de '+ info.label +' debe ser mayor al valor de '+ v.label;
              }
              if(res.ban){
                return res;
              }
            }
          }
        });
        return res;
      },
      getValue(){
        let filters_array = {};
        this.filters.forEach((v) => {
          let info = {};
          let ref = 'ref_'+ v.ref;
          if(v.type == 'DATE_RANGE'){
            ref = 'ref_'+ v.ref +'_'+ v.type_date_range;
            info.type_date_range = v.type_date_range;
          }
          let value_ref = '';
          if(!this.$refs[ref][0].$refs.input){
            value_ref = this.$refs[ref][0].getValue();
          }else{
            value_ref = this.$refs[ref][0].$refs.input._value;
          }
          info.value = Helpers.validateValue(value_ref);
          info.is_property = v.is_property;
          info.is_serialized = v.is_serialized;
          info.type = v.type;
          if(v.type == 'DATE_RANGE'){
            filters_array[v.ref +'_'+ v.type_date_range] = info;
          }else{
            filters_array[v.ref] = info;
          }
        });
        return '&filter='+ JSON.stringify(filters_array);
      }
    },
  }
  </script>
  