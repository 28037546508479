import MenuRepository from "../repositories/MenuRepository";
import ServiceResponse from "../../../libs/ServiceResponse/ServiceResponse";

class MenuService {
  context;
  menu_repository = null;

  constructor(context) {
    this.context = context;
    this.menu_repository = new MenuRepository();
  }

  async getMenuGeneral() {
    //cambios
    let response_service = await this.menu_repository.getMenuGeneral();
    if (!response_service.success) {
      return new ServiceResponse(false, {}, response_service.msg).toPromise();
    }
    return new ServiceResponse(true, response_service.data).toPromise();
  }
}

export default MenuService;
