<template>
  <div id="body-login" class="p-3">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-lg-6 col-xl-4">
        <v-card>
          <v-card-text class="py-4 px-4 px-md-5">
            <img src="https://staging.miofficemanager.com/apic/webroot/themes/admin/assets/images/logo-login.png" class="d-block" width="100%" alt="">
            <img src="https://pausasactivas.esenttia.co/esenttia-pausasa/public/front/images/logo.png" class="d-block" width="100%" alt="">

            <div class="mt-4 mt-lg-5">
              <form action="#" method="post" @submit.prevent="login">
                <div class="form-group">
                  <v-text-field
                  v-model="email"
                  filled
                  dense
                  :rules="[rules.requerido]"
                  label="Correo electrónico"
                  hint="ejemplo@desarrollo.com"
                  persistent-hint
                  append-icon="mdi-account-outline"
                  v-bind:attrs="{ autocomplete: 'email' }"
                ></v-text-field>
                </div>
                <div class="form-group">
                  <v-text-field
                    v-model="password"
                    filled
                    dense
                    :rules="[rules.requerido]"
                    :type="show ? 'text' : 'password'"
                    label="Contraseña"
                    hint="Escribe tu contraseña"
                    persistent-hint
                    :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="show = !show"
                    v-bind:attrs="{ autocomplete: 'current-password' }"
                  ></v-text-field>
                </div>
                <v-btn 
                  depressed 
                  color="primary" 
                  :loading="loading" 
                  :disabled="loading" 
                  class="d-table mx-auto" 
                  type="submit"
                >Ingresar</v-btn>
              </form>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoginController from '../controllers/LoginController';

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      show: false,
      loader: null,
      loading: false,
      rules: {
        requerido: value => !!value || 'Campo obligatorio'
      },
      login_controller: null
    };
  },
  mounted() {
    this.email = '';
    this.password = '';
    this.login_controller = new LoginController(this);
  },
  methods: {
    login() {
      this.login_controller.loginUser();
    }
  }
};
</script>
