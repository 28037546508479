import FilterSelectService from "../services/FilterSelectService";
import ErrorManager from "../../../../libs/ServiceResponse/ErrorManager";
import UI from  '../../../../helpers/UI';

class FilterSelectController {
  context;
  filter_select_service;

  constructor(context) {
    this.context = context;
    this.filter_select_service = new FilterSelectService();
    setTimeout(() => {
      this._readDataFromAPI();
    }, 1000);
  }

  _readDataFromAPI() {
    UI.loading('show');
    this.context.loading = true;
    this.filter_select_service.readDataFromAPI(this.context.filter_info.endpoint)
    .then((r) => {
      if(r.success == true) {
        this.context.loading = false;
        this.context.items = r.data;
        UI.loading('hide');
      }
    })
    .catch((e) => new ErrorManager(e, () => {
      this.context.loading = false;
      UI.loading('hide');
    }));
  }
}

export default FilterSelectController;