import CompanyRepository from "../repositories/CompanyRepository";

class CompanyService {
  company_repository;

  constructor() {
    this.company_repository = new CompanyRepository();
  }

  async infoCompany() {
    let r = await this.company_repository.infoCompany();
    return r;
  }
}

export default CompanyService;