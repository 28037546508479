import UI from '../../../core/helpers/UI';
import ListService from '../services/ListService';

class ListController {
  constructor(context) {
    this.context = context;
    this.list_service = new ListService();

    this.setupHeaders();
  }

  setupHeaders() {
    this.context.headers = [
      { text: 'Titulo', value: 'name' },
      { text: 'Código', value: 'code' },
      { text: '', value: 'actions' },
    ];
  }

  loadTemplates() {
    UI.loading('show');
    return this.list_service.listTemplates()
      .then((r) => {
        if (r.success === true) {
          this.context.lists = r.data.items;
        }
        return r;
      })
      .catch((e) => {
        UI.mensajeBad('', e.msg);
        throw e;
      })
      .finally(() => {
        UI.loading('hide');
      });
  }

  deleteTemplate(template_id) {
    UI.loading('show');
    return this.list_service.deleteTemplate(template_id)
      .then((r) => {
        if (r.success === true) {
          UI.mensajeOk('', r.msg);
        }
        return r;
      })
      .catch((e) => {
        UI.mensajeBad('', e.msg);
        throw e;
      })
      .finally(() => {
        UI.loading('hide');
      });
  }

  openDetail(template_id) {
    console.log('ListController.openDetail llamado con template_id:', template_id);
    const selectedTemplate = this.context.lists.find(item => item.template_id === template_id);
    console.log('selectedTemplate:', selectedTemplate);
    if (selectedTemplate) {
      console.log('Configurando selectedTemplate y abriendo diálogo');
      this.context.selectedTemplate = selectedTemplate;
      this.context.showDetailDialog = true;
    } else {
      console.log('No se encontró la plantilla con id:', template_id);
    }
  }

  closeDetail() {
    this.context.showDetailDialog = false;
    this.context.selectedTemplate = null;
  }
}

export default ListController;