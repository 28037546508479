import UI from "../../../../helpers/UI";
import ErrorManager from "../../../../libs/ServiceResponse/ErrorManager";
import DynamicViewService from "../services/DynamicViewService";

class DynamicViewController {
  context;
  dynamic_view_service;

  constructor(context) {
    this.context = context;
    this.dynamic_view_service = new DynamicViewService();
    this._getDataView();
  }

  _getView() {
    this.context.tp_loaded_data = false;
    UI.loading('show');
    this.dynamic_view_service.getView(
      this.context.endpoint,
      this.context.module,
      this.context.type,
      this.context.submodule,
      this.context.view_name,
    )
    .then((r) => {
      if(r.success == true) {
        this.context.tp_headers = r.data.pagination_props.headers;
        this.context.tp_endpoint = this.context.endpoint;
        this.context.tp_config_buttons = r.data.pagination_props.config_buttons;
        
        console.log('tp_config_buttons',this.context.tp_config_buttons);
        this.context.tp_filters = r.data.pagination_props.filters;
        this.context.tp_loaded_data = true;
        UI.loading('hide');
      }
    })
    .catch((e) => new ErrorManager(e, () => {
      UI.loading('hide');
    }));
  }

  _getDataView(){
    if(this.context.mockup_data && this.context.mockup_data.view.success){
      console.log('view', 'mockup');
      this.context.tp_headers = this.context.mockup_data.view.data.pagination_props.headers;
      // this.context.tp_endpoint = this.context.endpoint;
      this.context.tp_mockup_data = this.context.mockup_data.paginate;
      this.context.tp_config_buttons = this.context.mockup_data.view.data.pagination_props.config_buttons;
      this.context.tp_filters = this.context.mockup_data.view.data.pagination_props.filters;
      this.context.tp_loaded_data = true;
    }else{
      console.log('view', 'api');
      this._getView();
    }
  }
}

export default DynamicViewController;