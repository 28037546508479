import ListRepository from "../repositories/ListRepository";

class ListService {
  list_repository;

  constructor() {
    this.list_repository = new ListRepository();
  }

  async listBriefcases() {
    let r = await this.list_repository.listBriefcases();
    return r;
  }

  async toggleStateBriefcase(briefcase_id) {
    let r = await this.list_repository.toggleStateBriefcase(briefcase_id);
    return r;
  }
}

export default ListService;