export const URL = {
    DOMAIN:'127.0.0.1',
    BASE_URL:'http://127.0.0.1:8000/',
    URL_ENDPOINT: 'http://heaven.local/'
};

export const SYSTEM = {
  TOKEN: '',
  TOKEN_LOCAL: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYk9tIiwidGltZSI6MTYxNzgwODA2NiwidHlwZSI6IlNZUyJ9.4UsNgzZWXM3PjAIIbpTwuqthiCBEjXpGEVwMxiU4g2Y',
  TOKEN_HEAVEN: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYk9tIiwidGltZSI6MTYxODUzNzU5NywidHlwZSI6IlNZUyJ9.CCB59sCVeUNn00EvaGcvtTz3JOm7dNSIe6aeViaG-7I',
  TOKEN_DEV: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYk9tIiwidGltZSI6MTYxODkzNDAzNSwidHlwZSI6IlNZUyJ9.goFxxz2tAn86vhieBptNuDDGk1KmX0K7BFwKCGQzuIs',
  TOKEN_STG: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYk9tIiwidGltZSI6MTYxOTIxMDk5OCwidHlwZSI6IlNZUyJ9.8YCEMfK7zsReDd09Q44sN_aq4OTzmp6m6A33Sr56AJE',
  TOKEN_REV: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYk9tIiwidGltZSI6MTYxOTIxMTg3NCwidHlwZSI6IlNZUyJ9.3Oaru3D_87fBtYggVv5QzQsMTwHXb-lHFmqmH8K3nAI',
  TOKEN_PROD: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYk9tIiwidGltZSI6MTYxOTIxMTYxNSwidHlwZSI6IlNZUyJ9.GAragtgYFoomsNptPTBQqc_1p5UzAwSNUdF4LvVh-T8'
};

export const MONEDA_PESOS_COP = {
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    options: {
      locale: "es-ES",
      prefix: "COP$",
      suffix: "",
      length: 9,
      precision: 0
    }
};

export const NUMERO_ENTERO = {
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 9,
      precision: 0
    }
};

export const NUMERO_DECIMAL = {
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    options4: {
      locale: "es-ES",
      prefix: "",
      suffix: "",
      length: 9,
      precision: 2
    }
};

export const NUMERO_PORCENTAJE = {
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: false,
    options4: {
      locale: "es-ES",
      prefix: "",
      suffix: "",
      length: 9,
      precision: 2
    }
};