import UI from  '../../../core/helpers/UI';
import DetailService from '../services/DetailService';

class DetailController {
  context;
  detail_service;
  is_valid;

  constructor(context) {
    this.context = context;
    this.detail_service = new DetailService();

    if(this.context.$route.params.id) {
      this.context.user_id = parseInt(this.context.$route.params.id);
      this._infoUser();
    }
  }

  _infoUser() {
    UI.loading('show');
    this.detail_service.infoUser(this.context.user_id)
    .then((r) => {
      if(r.success == true) {
        this.context.user_id = r.data.usuario_id;
        this.context.photo = r.data.foto;

        let first_name = r.data.pri_nombre != null ? r.data.pri_nombre : '';
        let second_name = r.data.seg_nombre != null ? r.data.seg_nombre : '';
        let first_surname = r.data.pri_apellido != null ? r.data.pri_apellido : '';
        let second_surname = r.data.seg_apellido != null ? r.data.seg_apellido : '';

        this.context.names = first_name + ' ' + second_name;
        this.context.surnames = first_surname + ' ' + second_surname;

        this.context.position = r.data.name_position;
        this.context.type = r.data.name_tipo;
        this.context.phone_number = r.data.telefono;
        this.context.email = r.data.email;
        this.context.qr_code = r.data.qr_code;

        this.context.website = r.data.website;
        this.context.twitter = r.data.twitter;
        this.context.facebook = r.data.facebook;
        this.context.linkedin = r.data.linkedin;
        this.context.instagram = r.data.instagram;
        this.context.skype = r.data.skype;

        if(r.data.code_template) {
          this.context.template = r.data.code_template;
        }

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  openQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , true);
  }

  closeQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , false);
  }
}

export default DetailController;