import Helpers from "../helpers/Helpers";
import Core from "../helpers/Core";
import { SYSTEM } from "./Const";
import Enviroment from "./Enviroment";

export default class System{

    preInitVue(){}

    setTokens(){
      if(Helpers.isLogin()){
        let domain = Helpers.getBaseUrl();
        if(Enviroment.getTokenDefault()){
          Core.setToken(Enviroment.getTokenDefault());
        }else if(Enviroment.getTokenInitial() == 'DEV'){
          Core.setToken(SYSTEM.TOKEN_DEV);
        }else if(!Helpers.isEmpty(window.ENVIROMENT.TOKEN_BACKEND)){
          Core.setToken(window.ENVIROMENT.TOKEN_BACKEND);
        }else if(domain.indexOf('localhost') > -1 || domain.indexOf('heaven.local') > -1){
          Core.setToken(SYSTEM.TOKEN_LOCAL);
        }else if(domain.indexOf('app.produccion') > -1){
          Core.setToken(SYSTEM.TOKEN_DEV);
        }else if(domain.indexOf('produccionsitio') > -1){
          Core.setToken(SYSTEM.TOKEN_HEAVEN);
        }else if(domain.indexOf('appstaging.mioffice') > -1){
          Core.setToken(SYSTEM.TOKEN_STG);
        }else if(domain.indexOf('despliege.mioffice') > -1){
          Core.setToken(SYSTEM.TOKEN_REV);
        }else if(domain.indexOf('officemanager') > -1){
          Core.setToken(SYSTEM.TOKEN_PROD);
        }else{
          alert('ERRR TK001');
        }
      }
    }

    onCreatedVue(){
        var token = Core.getToken();
        if(token == null  && !Helpers.isLogin() && !Helpers.isModule('/csmaquetacion/') ){
          location.href = Helpers.urlmodulo('crcore','');
        }
    }

    generateVisitId(){
        let visit_id = localStorage.getItem("visit_id");
        if(Helpers.isEmpty(visit_id)){
            let time = (new Date().getTime()) - 1000000000000;
            let rand = Math.floor(Math.random() * (99 - 1 + 1)) + 99;
            localStorage.setItem("visit_id", time +'-'+ rand);
        }
    }
}
