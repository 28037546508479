import ServiceResponseExeption from "./ServiceResponseExeption";

class ErrorManager{

    constructor(error ,custom_message = null){
        if(error.name == ServiceResponseExeption.name){
            if(custom_message != null){
                custom_message();
            }
        }else{
            console.log(error);
        }
    }

}

export default ErrorManager;