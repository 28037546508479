import { SYSTEM } from "../framework/Const";

export default class Core{

    static setToken(token){
        localStorage.setItem("token",token);
    }

    static setTokenSys(){
        return localStorage.setItem("token", SYSTEM.TOKEN);
    }

    static getToken(){
        return localStorage.getItem("token");
    }

    static setCuentaString(data){
        localStorage.setItem("cuentastr",data);
    }

    static enableTokenExtend(){
        localStorage.setItem("is_token_extend","true");
    }

    static isTokenExtend(){
        return localStorage.getItem("is_token_extend");
    }

    static getCuentaString(){
        return localStorage.getItem("cuentastr");
    }

    static setCuentaStringPadre(data){
        localStorage.setItem("cuentastrpadre",data);
    }

    static getCuentaStringPadre(){
        return localStorage.getItem("cuentastrpadre");
    }

    static getVisitId(){
        return localStorage.getItem("visit_id");
    }
}
