<template>
  <div v-resize="onResize">
    <div>
      <v-expansion-panels accordion>            
        <v-expansion-panel v-for="item in menu" :key="item.informacion.label">
          <v-expansion-panel-header>
            <span><i :class="item.informacion.icon ? item.informacion.icon : 'mdi mdi-view-module'"></i></span>
            {{item.informacion.label}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels accordion v-model="accordion">
              <v-expansion-panel v-for="(item_hijo,index) in item.hijos" :key="index">
                <v-expansion-panel-header>
                  <span><i :class="item_hijo.informacion.icon ? item_hijo.informacion.icon : 'mdi mdi-format-list-bulleted-square'"></i></span>{{item_hijo.informacion.label}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul class="sub-menu list-unstyled">
                    <li  v-for="(info_hijo,index) in item_hijo.hijos" :key="index"  @click="accordion = null">
                      <a :href='info_hijo.informacion.link'>{{info_hijo.informacion.label}}</a>
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="bar-actions-nav px-3 options-menu" v-if="windowSize >= 992">
        <a :href="Helpers.urlInterna('crcore/cerrarsession')" class="btn shadow-none" v-if="estate_menu">
          <i class="fas fa-sign-out-alt fa-rotate-180"></i>
        </a>
        <a href="" class="btn shadow-none" v-if="estate_menu">
          <i class="fas fa-cog"></i>
        </a>
        <button type="button" class="btn shadow-none d-none d-lg-block" @click="hideMenu()">
          <i :class="!estate_menu ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventSystem } from '../../../libs/Events';
import Helpers from '../../../helpers/Helpers';
import MenuController from '../controllers/MenuController';
import Session from '../../../helpers/Session';

export default {
  name: 'MainMenu', // Asegúrate de actualizar aquí el nombre del componente
  data() {
    return {
      Helpers,
      menu:[],
      estate_menu:false,
      accordion: null,
      windowSize: {
        x: 0,
        y: 0
      },
    };
  },
  watch: {
    loader () {
    },
  },
  mounted() {
    new MenuController(this);

    EventSystem.on('toggle-menu', () => {
      if(this.hidde_menu_class) {
        this.hidde_menu_class = false;
      }else{
        this.hidde_menu_class = true;
      }
    });

    this.onResize();
  },
  methods: {
    login() {},

    hideMenu: function() {
      EventSystem.emit('toggle-menu',{});

      let menu_state = Session.get('menu_opened');
      if(menu_state == 'TRUE') {
          this.estate_menu = true;
        Session.set('menu_opened','FALSE');
      }else{
          this.estate_menu = false;
        Session.set('menu_opened','TRUE');
      }
    },

    onResize() {
      this.windowSize = window.innerWidth;

      if(this.windowSize < 991) { // Cambié 'this.windowResize' a 'this.windowSize'
        this.hidde_menu_class = false;
      } else {
        this.hidde_menu_class = true;
      }
    },
  }
};
</script>
