import DynamicViewRepository from "../repositories/DynamicViewRepository";

class DynamicViewService {
  dynamic_view_repository;
  
  constructor() {
    this.dynamic_view_repository = new DynamicViewRepository();
  }

  async getView(endpoint, module, type, submodule, view_name) {
    let query_params = "?module="+ module +"&type="+ type+"&submodule="+ submodule +"&view_name="+ view_name +"&view_ini=1";
    let response_service = await this.dynamic_view_repository.getView(endpoint, query_params);
    return response_service;
  }
}

export default DynamicViewService;