import PieChartRepository from "../repositories/PieChartRepository";

class PieChartService {
  pie_chart_repository;
  
  constructor() {
    this.pie_chart_repository = new PieChartRepository();
  }

  async getDataChart(endpoint) {
    let response_service = await this.pie_chart_repository.getDataChart(endpoint);
    return response_service;
  }
}

export default PieChartService;