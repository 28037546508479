<template>
  <section class="main">
    <div class="container-fluid main-box-content contentModulo py-2 px-3 px-lg-2">
      <div class="custom-breadcrumb mb-3">
        <ul>
          <li><a href="">Ejemplos</a></li>
          <li>Graficas</li>
        </ul>
      </div>
      <div class="px-md-3">
        <v-card>
          <v-card-title>Grafica de torta</v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-sm-6">
                <pie-chart
                  title_chart="Prueba de grafica con lista"
                  :data_list="data_list"
                  endpoint=""
                ></pie-chart>
              </div>

              <div class="col-sm-6">
                <pie-chart
                  title_chart="Prueba de grafica con endpoint"
                  :data_list="[]"
                  endpoint="api/core/examples-get-data-chart"
                ></pie-chart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import PieChart from '../../charts/views/PieChart.vue';

export default {
  name: 'ExampleChart',
  components: { PieChart },
  data() {
    return {
      endpoint_simple: 'api/core/examples-no-pagination',
      data_list: [
        {
          name: "Titulo 1",
          y: 12.5,
        },
        {
          name: "Titulo 2",
          y: 29.9,
        },
        {
          name: "Titulo 3",
          y: 32.1,
        },
      ],
      load_controller: null,
    };
  },
  mounted() {
    //this.load_controller = new LoadFileController(this);
  },
  methods: {},
};
</script>
