import Rest from "../../../../libs/Rest";

class PaginatorRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  readDataFromAPISimple(endpoint) {
    this.rest.setParams('');
    this.rest.setEndPoint(endpoint);
    return this.rest.get();
  }

  readDataFromAPIExtended(endpoint, query_params) {
    this.rest.setParams(''+query_params);
    this.rest.setEndPoint(endpoint);
    return this.rest.get();
  }

  deleteFromAPI(endpoint, id, type_petition) {    
    this.rest.setParams('');
    this.rest.setEndPoint(endpoint);
    if(type_petition == 'PUT'){
      return this.rest.put(id);
    }else{
      return this.rest.delete(id);
    }
  }
}

export default PaginatorRepository;