import UI from "../helpers/UI";
import Rest from "./Rest";

class File{

  uploadFile(eventfile, route, cb) {
    UI.loading("show");
    let rest = new Rest();
    rest.loadEndPointStandarLoadFile();
    rest.setParams({destino:route});
    rest.file(eventfile).then((info) => {
      UI.loading("hide");
      cb(info);
    }).catch(() => {
        UI.loading("hide");
    });
  }

}

export default File;