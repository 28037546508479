<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'WebLayout',
  data() {
    return {
      header: null,
    };
  },
  mounted() {
  },
  methods: {
  }
}
</script>