<template>
    <div>
        HEADERS
    </div>
</template>
<script>
export default {
    name: 'AppHeader', 
    data() {
        return {};
    },
    mounted() {
    },
    methods: {
    }
}
</script>
