import Web from '../../core/layouts/Web.vue';
import Working from './views/Working.vue';
// import Home from './views/Home.vue';
import Profile from './views/Profile.vue';
import Briefcase from './views/Briefcase.vue';
import Company from './views/Company.vue';
import Lector from './views/Lector.vue';

window.routes.push({ path: "/web", redirect: "/web/profile" });

window.routes.push({
  path: '/web',
  name: 'web',
  
  component: Web,
  children: [
    {
      path: 'home',
      name: 'home',
      component: Lector,
    },
    {
      path: 'profile/:id?',
      name: 'profile',
      component: Profile,
    },
    {
      path: 'briefcase',
      name: 'briefcase',
      component: Briefcase,
    },
    {
      path: 'company',
      name: 'company',
      component: Company,
    },
    {
      path: 'lector',
      name: 'lector',
      component: Lector,
    },
    {
      path: 'working',
      name: 'working',
      component: Working,
    },
  ],
});