import UpdateRepository from "../repositories/UpdateRepository";

class UpdateService {
  update_repository;

  constructor() {
    this.update_repository = new UpdateRepository();
  }

  async infoCompany() {
    let r = await this.update_repository.infoCompany();
    return r;
  }

  async updateCompany(photo, list_address, scheduled, website, facebook, instagram, twitter, linkedin, skype, email, url_company, url1, url2, url3, descripcion1, descripcion2, descripcion3) {
    let r = await this.update_repository.updateCompany(photo, JSON.stringify(list_address), scheduled, website, facebook, instagram, twitter, linkedin, skype, email, url_company, url1, url2, url3, descripcion1, descripcion2, descripcion3);
    return r;
  }
}

export default UpdateService;