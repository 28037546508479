<template>
  <div>
    <div v-resize="onResize" class="panel-client" v-if="!overwrite_theme">
      <router-view @reloadpage="reloadpage" :key="idPage"></router-view>
    </div>
    
    <div v-resize="onResize" v-if="overwrite_theme">
      <web-theme @reloadpage="reloadpage" :key="idPage"></web-theme>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebLayout',
  
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0
      },
      idPage: 1,
      overwrite_theme: false
    }
  },
  
  mounted() {
    this.onResize();
    // Comentado para no sobrescribir el tema por defecto
    // this.overwrite_theme = true;
  },
  
  methods: {
    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight
      };
    },
    
    reloadpage() {
      this.idPage += 1;
    }
  }
}
</script>