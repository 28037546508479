import Rest from '../../../core/libs/Rest';

class ManageRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  createBriefcase(title, image, description, link, link_name) {
    this.rest.setParams({title:title, image:image, description:description, link:link, link_name:link_name});
    this.rest.setEndPoint('api/card/briefcase/store-briefcase');
    return this.rest.post();
  }

  updateBriefcase(briefcase_id, title, image, description, link, link_name) {
    this.rest.setParams({title:title, image:image, description:description, link:link, link_name:link_name});
    this.rest.setEndPoint('/api/card/briefcase/update-briefcase');
    return this.rest.put(briefcase_id);
  }

  infoBriefcase(briefcase_id) {
    this.rest.setParams('/'+briefcase_id);
    this.rest.setEndPoint('/api/card/briefcase/show-briefcase');
    return this.rest.get();
  }

  toggleStateBriefcase(briefcase_id) {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/briefcase/toogle-state-briefcase');
    return this.rest.put(briefcase_id);
  }
}

export default ManageRepository;