import Rest from '../../../core/libs/Rest';
// import { DETAIL_MOCKUP } from '../mockups/Detail';

class DetailRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoUser(user_id) {
    this.rest.setParams('/'+user_id);
    this.rest.setEndPoint('api/card/contact/show-contact');
    return this.rest.get();
  }
}

export default DetailRepository;