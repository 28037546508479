import Rest from '../../../core/libs/Rest';
import axios from 'axios';

// import { MANAGE_MOCKUP } from '../mockups/Manage';

class ManageRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  createTemplate(name, code) {
    this.rest.setParams({name:name, code:code});
    this.rest.setEndPoint('/api/card/template/store-template');
    return this.rest.post();
  }

  updateTemplate(template_id, name, code) {
    this.rest.setParams({name:name, code:code});
    this.rest.setEndPoint('/api/card/template/update-template');
    return this.rest.put(template_id);
  }

  infoTemplate(template_id) {
    console.log('Rest service:', this.rest);
    console.log('Template ID:', template_id);
    this.rest.setParams('/' + template_id);
    this.rest.setEndPoint('/api/card/template/show-template');
    console.log('Antes de llamar a get()');
    return this.rest.get();
  }
}

export default ManageRepository;