<template>
  <div>
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" href="#/web/home/">
        <span class="fw-700 fz-14 mt-1 text-white">Home</span>

        <v-icon class="fz-34 text-white">mdi-home</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" href="#/web/company/">
        <span class="fw-700 fz-14 mt-1 text-white">Empresa</span>

        <v-icon class="fz-34 text-white">mdi-factory</v-icon>
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" href="#/web/briefcase">
        <span class="fw-700 fz-14 mt-1 text-white">Portafolio</span>

        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <section class="p-0 section-public">
      <qrcode-stream @decode="onDecode"></qrcode-stream>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import LectorController from '../controllers/LectorController';

export default {
  name: 'LectorView',
  data() {
    return {
      Helpers,
      header: 'contact',

      lector_controller: null
    };
  },

  mounted() {
    this.lector_controller = new LectorController(this);
  },

  methods: {
    onDecode(decodedString) {
      this.lector_controller.onDecode(decodedString);
    }
  }
}
</script>