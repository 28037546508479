import UI from  '../../../core/helpers/UI';
// Removed the unused import: import File from "../../../core/helpers/File";
import ListService from '../services/ListService';

class ListController {
  context;
  list_service;

  constructor(context) {
    this.context = context;
    this.list_service = new ListService();

    this.context.headers = [
      { text: '', value: 'checkbox' },
      { text: 'Titulo', value: 'title' },
      { text: 'Imagen', value: 'photo' },
      { text: 'Descripción', value: 'description' },
      { text: '', value: 'actions' },
    ];

    this._listBriefcases();
  }

  _listBriefcases() {
    this.context.lists = [];
    UI.loading('show');
    this.list_service.listBriefcases()
    .then((r) => {
      if(r.success == true) {
        this.context.lists = r.data.items;
        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  toggleStateBriefcase(briefcase_id, position, state) {
    UI.loading('show');
    this.list_service.toggleStateBriefcase(briefcase_id)
    .then((r) => {
      if(r.success == true) {
        UI.mensajeOk('', r.msg);

        if(state == 1) {
          this.context.lists[position].state = 2;
        } else {
          this.context.lists[position].state = 1;
        }
        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  openDetail(briefcase_id) {
    this.context.$set(this.context.dialog_detail, briefcase_id, true);
  }
  
  closeDetail(briefcase_id) {
    this.context.$set(this.context.dialog_detail, briefcase_id, false);
  }
}

export default ListController;