import UI from  '../../../core/helpers/UI';
import File from "../../../core/helpers/File";
import MainService from '../services/MainService';

class ManageController {
  context;
  manage_service;
  valid;

  constructor(context) {
    this.context = context;
    this.manage_service = new MainService();

    this.context.list_created_card = [
      { value: 1, text: 'Si' },
      { value: 2, text: 'No' },
    ];

    this._infoProfile();
  }

  _infoProfile() {
    UI.loading('show');
    this.manage_service.infoProfile()
    .then((r) => {
      if(r.success == true) {
        this.context.user_id = r.data.usuario_id;

        if(r.data.foto) {
          this.context.photo = r.data.foto;
        } else {
          this.context.photo = '';
        }

        this.context.first_name = r.data.pri_nombre;
        this.context.second_name = r.data.seg_nombre;
        this.context.first_surname = r.data.pri_apellido;
        this.context.second_surname = r.data.seg_apellido;
        
        // if(r.data.qr_code) {
        //   this.context.show_card = '1';
        // } else {
        //   this.context.show_card = '2';
        // }

        this.context.show_card = '1';

        if(r.data.telefono != null && r.data.telefono != 'null') {
          this.context.phone_number = r.data.telefono;
        }

        if(r.data.email != null && r.data.email != 'null') {
          this.context.email = r.data.email;
        }

        if(r.data.website != null && r.data.website != 'null') {
          this.context.website = r.data.website;
        }

        if(r.data.twitter != null && r.data.twitter != 'null') {
          this.context.twitter = r.data.twitter;
        }

        if(r.data.facebook != null && r.data.facebook != 'null') {
          this.context.facebook = r.data.facebook;
        }

        if(r.data.linkedin != null && r.data.linkedin != 'null') {
          this.context.linkedin = r.data.linkedin;
        }

        if(r.data.instagram != null && r.data.instagram != 'null') {
          this.context.linkedin = r.data.instagram;
        }

        if(r.data.skype != null && r.data.skype != 'null') {
          this.context.skype = r.data.skype;
        }

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  manageMyProfile() {
    this.valid = true;

    if(this.context.website && !this.validatedUrl(this.context.website)) {
      UI.mensajeBad("", "Formato de URL de sitio web incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.facebook && !this.validatedUrl(this.context.facebook)) {
      UI.mensajeBad("", "Formato de URL de Facebook incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.instagram && !this.validatedUrl(this.context.instagram)) {
      UI.mensajeBad("", "Formato de URL de Instagram incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.twitter && !this.validatedUrl(this.context.twitter)) {
      UI.mensajeBad("", "Formato de URL de Twitter incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.context.linkedin && !this.validatedUrl(this.context.linkedin)) {
      UI.mensajeBad("", "Formato de URL de Linkedin incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }

    if(this.valid && this.context.$refs.form_profile.validate()) {
      UI.loading('show');
      this.manage_service.updateMyProfile(
        this.context.phone_number,
        this.context.photo,
        this.context.website,
        this.context.facebook,
        this.context.instagram,
        this.context.twitter,
        this.context.linkedin,
        this.context.skype,
        this.context.show_card
      ).then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this._infoProfile();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  validatedUrl(str) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  managePassword() {
    this.is_valid = true;

    if(this.context.password && this.context.re_password && this.context.password != this.context.re_password) {
      UI.mensajeBad('', 'Las contraseñas deben ser iguales.');
      this.valid = false;
      return false;
    }

    if(this.is_valid && this.context.$refs.form_password.validate()) {
      UI.loading('show');
      this.manage_service.managePassword(this.context.password, this.context.re_password)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.context.$refs.form_password.reset();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  uploadPhoto(file) {
    if(file) {
      File.uploadFile(file, '/my-profile', (r)=> {
        this.context.photo = r.data.rutacuenta_encode_token;
      });
      
      setTimeout(() => {
        this.context.upload_photo = null;
      }, 250);
    }
  }

  deletePhoto() {
    this.context.photo = '';
    this.context.upload_photo = null;
  }
}

export default ManageController;