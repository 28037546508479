import UI from  '../../../core/helpers/UI';
import ManageService from '../services/ManageService';
import axios from 'axios';

class ManageController {
  context;
  manage_service;
  is_valid;

  constructor(context) {
    this.context = context;
    this.manage_service = new ManageService();

    if(this.context.$route.params.id) {
      this.context.template_id = parseInt(this.context.$route.params.id);
      this.context.is_edit = true;

      this._infoTemplate(this.context.template_id);
    }
  }
  obtenerPlantillaPorId(templateId) {
    if (axios && typeof axios.get === 'function') {
      return axios.get(`/card/template/show-template/${templateId}`);
    } else {
      console.error('Axios no está disponible');
      return Promise.reject(new Error('Axios no está disponible'));
    }
  }
  _infoTemplate(template_id) {
    UI.loading('show');
    this.manage_service.infoTemplate(template_id)
    .then((r) => {
      if(r.success == true) {
        this.context.template_id = r.data.template_id;
        this.context.name = r.data.name;
        this.context.code = r.data.code;

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  manageTemplate() {
    if(this.context.is_edit) {
      this._updateTemplate();
    } else {
      this._createTemplate();
    }
  }

  _createTemplate() {
    this.is_valid = true;

    if(this.context.$refs.form_template.validate() && !this.context.code) {
      UI.mensajeBad('', 'Código de plantilla obligatorio');
      this.is_valid = false;
    }

    if(this.is_valid && this.context.$refs.form_template.validate()) {
      UI.loading('show');
      this.manage_service.createTemplate(this.context.name, this.context.code)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.context.$refs.form_template.reset();
          this.context.code = '';
          window.location = '#/templates/list';
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  _updateTemplate() {
    this.is_valid = true;

    if(this.context.$refs.form_template.validate() && !this.context.code) {
      UI.mensajeBad('', 'Código de plantilla obligatorio');
      this.is_valid = false;
    }

    if(this.is_valid && this.context.$refs.form_template.validate()) {
      UI.loading('show');
      this.manage_service.updateTemplate(this.context.template_id, this.context.name, this.context.code)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.context.$refs.form_template.reset();
          this.context.code = '';
          this.context.is_edit = false;
          window.location = '#/templates/list';
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }
}

export default ManageController;