import Rest from '../../../core/libs/Rest';
// import { BRIEFCASE_MOCKUP } from '../mockups/Briefcase';

class BriefcaseRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listBriefcases() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api/card/public/list-briefcases');
    return this.rest.get();
  }
}

export default BriefcaseRepository;