<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Plantillas</li>
            <li>{{!is_edit ? 'Crear' : 'Actualizar'}} plantilla</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">{{!is_edit ? 'Crear' : 'Actualizar'}} plantilla</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form
              class="w-100"
              ref="form_template"
              method="post"
              v-model="form_valid"
              lazy-validation
              @submit.prevent="manageTemplate"
            >
              <v-text-field
                filled
                label="Titulo"
                v-model="name"
                :rules="[rules.requerido]"
              ></v-text-field>

              <!-- TinyMCE Editor -->
              <editor
                v-model="code"
                api-key="frv7e3d94mw151lmu92w94a9tuwh9zk0kr0en9ji04xgnjxo"
                :init="editorConfig"
              />

              <v-btn
                type="submit"
                rounded
                color="primary"
                min-width="150"
                class="d-table mx-auto mt-3"
              >
                Guardar
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import ManageController from '../controllers/ManageController';

export default {
  name: 'GestionPlantilla',
  components: {
    editor: Editor,
  },
  data() {
    return {
      name: '',
      code: '',
      template_id: null,
      is_edit: false,

      form_valid: true,
      rules: {
        requerido: (value) => !!value || 'Campo obligatorio',
        email: (value) =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          'Email incorrecto',
      },

      manage_controller: null,

      // Configuración del editor TinyMCE
      editorConfig: {
        height: 400,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat | code',
        output_format: 'html',
      },
    };
  },

  mounted() {
    this.manage_controller = new ManageController(this);
    const templateId = this.$route.params.id;
    if (templateId) {
      this.is_edit = true;
      this.template_id = templateId;
      this.obtenerDatosPlantilla(templateId);
    }
  },

  methods: {
    obtenerDatosPlantilla(templateId) {
      this.manage_controller
        .obtenerPlantillaPorId(templateId)
        .then((plantilla) => {
          this.name = plantilla.name;
          this.code = plantilla.code;
        })
        .catch((error) => {
          console.error('Error al obtener la plantilla:', error);
        });
    },
    manageTemplate() {
      this.manage_controller.manageTemplate();
    },
  },
};
</script>
