import UI from  '../../../core/helpers/UI';
import File from "../../../core/helpers/File";
import ManageService from '../services/ManageService';

class ManageController {
  context;
  manage_service;
  valid;

  constructor(context) {
    this.context = context;
    this.manage_service = new ManageService();

    this.context.is_edit = false;

    if(this.context.$route.params.id) {
      this.context.briefcase_id = parseInt(this.context.$route.params.id);
      this.context.is_edit = true;

      this._infoBriefcase();
    }
  }

  manageBriefcase() {
    if(this.context.is_edit) {
      this._updateBriefcase();
    } else {
      this._createBriefcase();
    }
  }

  _createBriefcase() {
    this.valid = true;

    if(this.context.$refs.form_briefcase.validate() && !this.context.image) {
      UI.mensajeBad('', 'Imagen obligatoria');
      this.valid = false;
    }

    if(this.valid && this.context.$refs.form_briefcase.validate()) {
      UI.loading('show');
      this.manage_service.createBriefcase(this.context.title, this.context.image, this.context.description, this.context.link, this.context.link_name)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          window.location = '#/briefcase/list';
          this.context.$refs.form_briefcase.reset();
          this.deleteImage();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  _updateBriefcase() {
    this.valid = true;

    if(this.context.$refs.form_briefcase.validate() && !this.context.image) {
      UI.mensajeBad('', 'Imagen obligatoria');
      this.valid = false;
    }

    if(this.valid && this.context.$refs.form_briefcase.validate()) {
      UI.loading('show');
      this.manage_service.updateBriefcase(this.context.briefcase_id, this.context.title, this.context.image, this.context.description, this.context.link, this.context.link_name)
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          window.location = '#/briefcase/list';
          this.context.briefcase_id = null;
          this.context.title = '';
          this.context.description = '';
          this.deleteImage();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  _infoBriefcase() {
    UI.loading('show');
    this.manage_service.infoBriefcase(this.context.briefcase_id)
    .then((r) => {
      if(r.success == true) {
        this.context.briefcase_id = r.data.briefcase_id;
        this.context.title = r.data.title;
        this.context.image = r.data.image;
        this.context.description = r.data.description;
        this.context.link = r.data.link;
        this.context.link_name = r.data.link_name;

        if(r.data.state == 1) {
          this.context.is_active = true;
        } else {
          this.context.is_active = false;
        }

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  uploadFile(file) {
    if(file) {
      File.uploadFile(file, '/briefcases', (r)=> {
        this.context.image = r.data.rutacuenta_encode_token;
      });
      
      setTimeout(() => {
        this.context.upload_photo = null;
      }, 250);
    }
  }

  deleteImage() {
    this.context.image = '';
    this.context.upload_photo = null;
  }

  toggleStateBriefcase() {
    UI.loading('show');
    this.manage_service.toggleStateBriefcase(this.context.briefcase_id)
    .then((r) => {
      if(r.success == true) {
        UI.mensajeOk('', r.msg);
        this._infoBriefcase();
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }
}

export default ManageController;