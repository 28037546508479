import SessionUser from "../libs/SessionUser";

class User{

    static isLogued(){
        let user_session = new SessionUser();
        return user_session.isLogued();
    }

    static getToken(){
        let user_session = new SessionUser();
        return user_session.getToken();
    }
}

export default User;
