import Principal from '../../core/layouts/Principal.vue';
import ProfileMain from './views/ProfileMain.vue';
import Manage from './views/Manage.vue';

window.routes.push({
  path: '/profile',
  component: Principal,
  children: [
    {
      path: '/profile/:userId?',
      name: 'profile',
      component: ProfileMain,
    },
    {
      path: 'manage',
      name: 'profileManage',
      component: Manage,
    },
  ],
});