import ManageRepository from "../repositories/ManageRepository";

class ManageService {
  manage_repository;

  constructor() {
    this.manage_repository = new ManageRepository();
  }

  async createTemplate(name, code) {
    let r = await this.manage_repository.createTemplate(name, code);
    return r;
  }

  async updateTemplate(template_id, name, code) {
    let r = await this.manage_repository.updateTemplate(template_id, name, code);
    return r;
  }

  async infoTemplate(template_id) {
    let r = await this.manage_repository.infoTemplate(template_id);
    return r;
  }
}

export default ManageService;