<template>
    <div>
        <filters-view
            :filters="filters"
            ref="filter_table_view"
        ></filters-view>
        <div class="text-center" v-if="filters.length > 0">
            <v-btn color="primary" type="button" x-large @click="filterData()">
                <v-icon class="mr-2">mdi-magnify</v-icon>
                Buscar
            </v-btn>
        </div>
        <br>
        <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :headers="headers"
            :items="passengers"
            :options.sync="options"
            :server-items-length="totalPassengers"
            :loading="loading"
            class="elevation-1"
        >
            <template v-slot:top>
                <v-text-field
                    style="text-align: left !important;"
                    v-model="search"
                    label="Buscar"
                    filled                    
                    :hint="message_hint_search"
                ></v-text-field>
            </template>
            <template #[`item.actions`]="{ item }">                <!-- Botones -->
                <v-tooltip bottom v-for="(item_button, index) in config_buttons" :key="index">
                    <template v-slot:activator="{ on, attrs }">
                        <!-- Enlace -->
                        <v-btn icon :color="item_button.color" v-bind="attrs" v-on="on" v-if="item_button.webservice.endpoint == null && item_button.open_modal.length == 0" :href="configureButtonUrl(item, item_button)" :target="item_button.new_tab == true ? '_blank' : ''">
                            <v-icon>{{ item_button.icon }}</v-icon>
                        </v-btn>
                        <!-- Servicio de eliminar -->
                        <v-btn icon :color="item_button.color" v-bind="attrs" v-on="on" v-if="item_button.webservice.endpoint && item_button.open_modal.length == 0" @click="deleteFromAPI(item, item_button)">
                            <v-icon>{{ item_button.icon }}</v-icon>
                        </v-btn>
                        <!-- Abrir modal -->
                        <v-btn icon :color="item_button.color" v-bind="attrs" v-on="on" v-if="item_button.open_modal.param" @click="openModal(item, item_button)">
                            <v-icon>{{ item_button.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ item_button.label }}</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Helpers from '../../../../helpers/Helpers';
import PaginatorViewController from '../controllers/PaginatorViewController';
export default {
    props: ['headers','endpoint', 'config_buttons', 'filters', 'mockup_data'],
    data: () => {
        return {
            Helpers,
            page: 1,
            totalPassengers: 0,
            numberOfPages: 0,
            passengers: [],
            loading: true,
            canGetData: true,
            options: {},
            search: '',
            filters_str: '',
            message_hint_search: '',

            paginator_view_controller: null
        };
    },
    mounted(){
        this.paginator_view_controller = new PaginatorViewController(this);
    },
    watch: {
        options: {
            handler() {
                this.paginator_view_controller.getTableData();
            },
        },
        search:{
            handler() {
                if(this.search.length > 2){
                    setTimeout(()=>{
                        this.paginator_view_controller.getTableData();
                    }, 2000);
                }else if(this.search.length == 0){
                    setTimeout(()=>{
                        this.paginator_view_controller.getTableData();
                    }, 2000);
                }
            },
        },
        deep: true
    },
    methods: {
        configureButtonUrl(item_entity, item_button){
            return this.paginator_view_controller.configureButtonUrl(item_entity, item_button);
        },
        deleteFromAPI(item_entity, item_button){
            return this.paginator_view_controller.deleteFromAPI(item_button['webservice']['endpoint'], item_entity[item_button.webservice.param]);
        },
        openModal(item_entity, item_button){
            this.$emit("click", {'quotation_id': item_entity[item_button.open_modal.param]});
        },
        filterData(){
            this.paginator_view_controller.filterData();
        }
    },
}
</script>