import Principal from '../../core/layouts/Principal.vue';
import Map from './views/Map.vue';

window.routes.push({
  path: '/map',
  name: 'map',
  component: Principal,
  children: [
    {
      path: '',
      name: '',
      component: Map,
    }
  ],
});