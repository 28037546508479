import ListRepository from "../repositories/ListRepository";

class ListService {
  list_repository;

  constructor() {
    this.list_repository = new ListRepository();
  }

  async listTemplates() {
    let r = await this.list_repository.listTemplates();
    return r;
  }

  async deleteTemplate(template_id) {

    let r = await this.list_repository.deleteTemplate(template_id);
    return r;
  }
}

export default ListService;