<template>
    <div>
        <v-select 
            filled
            v-model="p_model"
            :items="items" 
            :label="filter_info.label" 
            :item-value="filter_info.type_select_option.item_value" 
            :item-text="filter_info.type_select_option.item_text"
            clearable
        ></v-select>
    </div>
</template>

<script>
import FilterSelectController from '../controllers/FilterSelectController';
export default {
    props: ['filter_info'],
    data: () => {
        return {
            p_model: null,
            items: [],
            filter_select_controller: null,
        };
    },
    mounted(){
        this.filter_select_controller = new FilterSelectController(this);
    },
    methods: {
        getValue(){
            return this.p_model;
        }
    }
}
</script>