import ProfileRepository from "../repositories/ProfileRepository";

class ProfileService {
  profile_repository;

  constructor() {
    this.profile_repository = new ProfileRepository();
  }

  async infoProfile(contact_id) {
    let r = await this.profile_repository.infoProfile(contact_id);
    return r;
  }

  async cardAddContact(contact_id) {
    let r = await this.profile_repository.cardAddContact(contact_id);
    return r;
  }
}

export default ProfileService;