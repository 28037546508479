import Enviroment from "../../../helpers/Enviroment";
import Rest from "../../../libs/Rest";
 import SessionUser from "../../../libs/SessionUser"; // Línea eliminada

class MenuRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  // getMenuGeneral() {
  //   if (Enviroment.getBackArquitecture() == 'V2') {
  //     let end_point = '/api/crcore/cr-menu-obtener';
  //     this.rest.setParams('');
  //     this.rest.setEndPoint(end_point);
  //     return this.rest.post();
  //   } else {
  //     let end_point = '/api/crcore/cr-menu-obtener';
  //     this.rest.setParams('');
  //     this.rest.setEndPoint(end_point);
  //     return this.rest.get();
  //   }
  // }

  getMenuGeneral() {
    let base_url_app = Enviroment.getBaseUrlEndpoint();
    console.log('base_url_app', base_url_app);
    let session_user_renew = new SessionUser();
    let user_id = session_user_renew.getUserId();
    console.log('user_id', user_id);   
    this.rest.setBaseUrl(base_url_app + 'api/');
    this.rest.setParams('/'+ user_id);
    this.rest.setEndPoint('crsystem/menu-general-generar');
    return this.rest.get();
  }
}

export default MenuRepository;
