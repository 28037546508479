<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3">
          <ul>
            <li>Inicio</li>
            <li>Usuarios</li>
            <li>Detalle del usuario</li>
          </ul>
        </div>

        <div class="title">
          <h1 class="my-0 fw-700">Detalle del usuario</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text :class="!template ? 'pb-0 px-md-4 pt-md-4' : 'px-md-4 py-md-4'">
            <div class="row" v-if="!template">
              <div class="col-md-4 col-lg-3 mb-4">
                <img :src="File.fileUrl(photo)" class="d-block mx-auto mb-3" width="100%" alt="" v-if="photo">

                <h4 class="fw-700 fz-26 text-center my-0 text-black">{{names + ' ' + surnames}}</h4>
                
                <div class="d-flex justify-content-center mt-4">
                  <v-btn color="success" max-width="160" :href="'#/users/manage/'+user_id">Editar usuario</v-btn>
                </div>
              </div>
              <div class="col-md-8 col-lg-9">
                <div class="row">
                  <div class="col-md-4 mb-4 mb-md-5" v-if="position">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Cargo:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{position}}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="type">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Tipo:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{type}}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="phone_number">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Teléfono:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{phone_number}}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">Correo electrónico:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">andres@miofficemanager.com</p>
                  </div>
                  <div class="col-12 mb-4 mb-md-5" v-if="qr_code">
                    <v-btn color="primary" max-width="160" class="d-flex fw-600 rounded" @click="openQrCode()">
                      <v-icon class="mr-2">mdi-qrcode</v-icon>
                      Ver código QR
                    </v-btn>

                    <v-dialog v-model="dialog_qr[0]" width="290">
                      <v-card>
                        <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                          <h1 class="my-0">Código QR</h1>
                          <v-btn icon color="danger" @click="closeQrCode()">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>

                        <v-card-text class="scroll-modal-quotations">
                          <div v-html="qr_code" class="qr-code"></div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="col-12 mb-4">
                    <ul class="mx-0 my-0 p-0 list-unstyled d-flex align-items-center justify-content-start flex-wrap">
                      <li class="mx-2 mb-3" v-if="website">
                        <v-btn icon outlined large color="success" target="_blank" :href="website">
                          <v-icon>mdi-web</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="twitter">
                        <v-btn icon outlined large color="success" target="_blank" :href="twitter">
                          <v-icon>mdi-twitter</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="facebook">
                        <v-btn icon outlined large color="success" target="_blank" :href="facebook">
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="linkedin">
                        <v-btn icon outlined large color="success" target="_blank" :href="linkedin">
                          <v-icon>mdi-linkedin</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="instagram">
                        <v-btn icon outlined large color="success" target="_blank" :href="instagram">
                          <v-icon>mdi-instagram</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="skype">
                        <v-btn icon outlined large color="success" target="_blank" :href="skype">
                          <v-icon>mdi-skype</v-icon>
                        </v-btn>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="template-code" v-if="template" v-html="template"></div>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import DetailController from '../controllers/DetailController';
import File from "../../../core/helpers/File";

export default {
  name: 'UserDetail',
  data() {
    return {
      Helpers,
      File,

      dialog_qr: {},
      user_id: null,

      photo: '',
      names: '',
      surnames: '',
      position: '',
      type: '',
      phone_number: '',
      email: '',
      qr_code: '',

      website: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      instagram: '',
      skype: '',

      template: '',

      password: '',
      show_pass: false,
      re_password: '',
      show_re_pass: false,

      form_valid: true,
      rules: {
        requerido: value => !!value || 'Campo obligatorio',
        email: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email incorrecto',
      },

      detail_customer_controller: null
    };
  },

  mounted() {
    this.detail_customer_controller = new DetailController(this);
  },

  methods: {
    openQrCode() {
      this.detail_customer_controller.openQrCode();
    },

    closeQrCode() {
      this.detail_customer_controller.closeQrCode();
    }
  }
}
</script>