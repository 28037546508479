import Rest from '../../../core/libs/Rest';
import { LECTOR_MOCKUP } from '../mockups/Lector';

class LectorRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  deleteContact(contact_id) {
    this.rest.setParams('');
    this.rest.setEndPoint('api/contacts/delete-contact');
    return this.rest.peticionMockupV2('DELETE', contact_id, LECTOR_MOCKUP.DELETE_CONTACT);
  }
}

export default LectorRepository;