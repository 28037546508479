import FileLib from '../libs/File';
import Enviroment from "./Enviroment";  // Mantén esta línea como está
console.log('File.js cargado');
class File {
  static fileUrlbrief(filePath) {
    // Verifica si filePath está definido y no es vacío
    console.log("Evento recibido:", filePath);

    if (!filePath) {
        console.error("El archivo no tiene un nombre válido:", filePath);
        return ''; // Devuelve una cadena vacía si no hay archivo
    }
    // Construye la URL completa
    return `${filePath}`;
}
  static uploadFile(eventfile, route, cb) {
    var file = new FileLib();
    file.uploadFile(eventfile, route, (information) => {
      cb(information);
    });
  }

//   static fileUrl(file_route) {
//     // Primero, decodificamos la ruta si contiene un token
//     // const decodedRoute = this.decodeTokenizedRoute(file_route);
// console.log(file_route);
//     if (Enviroment.getExternalLoadFile() === 'true') { 
//       console.log('false');
//  // Usa Enviroment en lugar de Environment
//       return `${Enviroment.getExternalBaseUrl()}zfiles/archivos/users/`+file_route;  // Usa Enviroment.getExternalBaseUrl()
//     } else {
//       console.log('true');

//       return `${Enviroment.getUrlZfiles()}`;  // Usa Enviroment.getUrlZfiles()
//     }
//   }

  static fileUrl(file_route) {
    console.log('Entered fileUrl with:', file_route);
    try {
      if (Enviroment.getExternalLoadFile() === 'true') {
        console.log('Using external base URL');
        return `${Enviroment.getExternalBaseUrl()}zfiles/archivos/users/` + file_route;
      } else {
        console.log('Using internal base URL');
        return `${Enviroment.getUrlZfiles()}`;
      }
    } catch (error) {
      console.error('Error in fileUrl:', error);
      return '';
    }
  }
  
  static fileUrlb(file_route) {
    console.log('Entered fileUrl with:', file_route);
    try {
      if (Enviroment.getExternalLoadFile() === 'true') {
        console.log('Using external base URL');
        return  file_route;
      } else {
        console.log('Using internal base URL');
        return `${Enviroment.getUrlZfiles()}`;
      }
    } catch (error) {
      console.error('Error in fileUrl:', error);
      return '';
    }
  }
  
  static decodeTokenizedRoute(route) {
    // Si la ruta contiene '-tk-', la decodificamos
    if (route.includes('-tk-')) {
      const [token, encodedPath] = route.split('-tk-');
      return decodeURIComponent(encodedPath);
    }
    // Si no, devolvemos la ruta original
    return route;
  }

  static getUrlZfiles() {
    return Enviroment.getEnvVariable('VUE_APP_API_URL') || 'https://soyesenttiaqa.life/';  // Usa Enviroment.getEnvVariable
  }

  static async safeUploadFile(eventfile, route) {
    return new Promise((resolve, reject) => {
      this.uploadFile(eventfile, route, (information) => {
        if (information.error) {
          reject(new Error(information.error));
        } else {
          resolve(information);
        }
      });
    });
  }
}

export default File;