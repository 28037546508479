import Rest from "../../../../libs/Rest";

class FilterSelectRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  readDataFromAPI(endpoint) {
    this.rest.setParams('');
    this.rest.setEndPoint(endpoint);
    return this.rest.get();
  }
}

export default FilterSelectRepository;