<template>
  <div>
    MENU CUSTOM
  </div>
</template>
<script>

export default {
  name: 'CustomMenu',
  data() {
    return {
      menu:[]
    };
  },
  mounted() {},
  methods: {}
};
</script>